import React from "react";
// import { useParams } from "react-router-dom";
import { ExitIcon, HomeIcon } from "../styles/AppIcons";
import { Box, Button, Flex } from "../mkui";
import { ToastManager } from "../components/Toast/ToastManager";
import { Header } from "../components/Header";
import { useNavigation } from "../components/hooks/useNavigation";
import {
	defaultPreviewPreference,
	UserPreferencesKeys,
	useUserPreferences,
} from "../components/hooks/useUserPreferences";
// import { getRoutePrefix } from "../lib/utils";

const PostLeave = () => {
	const navigate = useNavigation();
	const [previewPreference] = useUserPreferences(
		UserPreferencesKeys.PREVIEW,
		defaultPreviewPreference
	);
	
	return (
		<Flex direction="column" className="w-full h-full">
			<Box className="h-8" data-testid="header">
				<Header isPreview />
			</Box>
			<Flex
				justify="center"
				direction="column"
				align="center"
				className="relative flex-1 -mt-20"
			>
				<div className="text-4xl md:text-5xl origin-80%_bottom] animate-waving">👋</div>
				<div className="text-2xl mt-6">
					Du hast die Veranstaltung verlassen
				</div>
				<div className="text-base mt-4 text-center">
					Einen schönen Tag noch
					{previewPreference.name && (
						<span>
							, {previewPreference.name}
						</span>
					)}
					!
				</div>
				<Flex align="center" direction="col" className="mt-7 gap-4">
					<span className="text-lg">
						Versehentlich verlassen?
					</span>
					<Button className="flex flex-row items-center"
						onClick={() => {
							navigate("/preview");
							ToastManager.clearAllToast();
						}}
						data-testid="join_again_btn"
					>
						<ExitIcon className="inline" />
						<span className="ml-2">
							Wieder eintreten
						</span>
					</Button>
				</Flex>
				<Flex align="center" direction="col" className="mt-7 gap-4">
					<span className="text-lg">
						Neu anmelden?
					</span>
					<Button className="flex flex-row items-center"
						onClick={() => {
							navigate("/");
							ToastManager.clearAllToast();
						}}
						data-testid="back_to_home_btn"
					>
						<HomeIcon className="inline" />
						<span className="ml-2">
							Zur Homepage
						</span>
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default PostLeave;
