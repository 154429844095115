/** @jsxImportSource @emotion/react */
import tw, {css, theme} from 'twin.macro'


const verticalStyles = css`
height: ${theme('components.divider.verticalHeight')};
width: ${theme('components.divider.width')};
background-color: ${theme('colors.app.divider')};
`

const horizontalStyles = css`
height: ${theme('components.divider.width')};
width: ${theme('components.divider.horizontalWidth')};
background-color: ${theme('colors.app.divider')};
`

const getVerticalSpace = (space: number) => {
	switch(space) {
		case 2:
			return tw`mx-2`
		case 3:
			return tw`mx-3`
		case 4:
			return tw`mx-4`
		case 5:
			return tw`mx-5`
		case 6:
			return tw`mx-6`

		default:
			return tw`mx-1`
	}
};

const getHorizontalSpace = (space: number) => {
	switch(space) {
		case 2:
			return tw`my-2`
		case 3:
			return tw`my-3`
		case 4:
			return tw`my-4`
		case 5:
			return tw`my-5`
		case 6:
			return tw`my-6`

		default:
			return tw`my-1`
	}
};

interface DividerProps {
	gap?: number;
	css?: any;
}

type DividerPropTypes = JSX.IntrinsicElements["span"] & DividerProps;

export const VerticalDivider = ({gap, css}:DividerPropTypes) => (
	<span css={[
		verticalStyles,
		gap && getVerticalSpace(gap),
		css && css
	]}
	/>
);

export const HorizontalDivider = ({gap, css}:DividerPropTypes) => (
	<span css={[
		horizontalStyles,
		gap && getHorizontalSpace(gap),
		css && css
	]}
	/>
);
