import resolveConfig from 'tailwindcss/resolveConfig'
import myConfig from '../tailwind.config.js'

const tailwindConfig = resolveConfig(myConfig as any)


export const useTheme = () => {
	return {
		colors: tailwindConfig.theme?.colors,
		//@ts-ignore
		appColors: tailwindConfig.theme?.colors?.app,
		typography: tailwindConfig.theme?.typography,
		components: tailwindConfig.theme?.components,
		aspectRatio: {width: 1, height: 1},
	}
}