import ClipLoader from 'react-spinners/ClipLoader';

export default function Spinner ({size = 100}) {
	return (
		<ClipLoader 
			size={size}
			color={"text-app-primary"}
			loading={true}
		/>
	)
}
