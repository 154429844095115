import { useCallback, useState } from "react";
import {
	CheckIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	HandRaiseIcon,
	PeopleIcon,
} from "@100mslive/react-icons";
import cn from 'mxcn';
import { Dropdown, Flex } from "../../mkui";
// import { isInternalRole } from "../../lib/utils";
import {textEllipsis} from "../../styles/styles";
import {Role, roleNamesMap} from "../../lib/peerTypes";

const hiddenRoles = [Role.Commander, Role.Director, Role.Viewer, Role.MK_Monitor];

export const ParticipantFilter = ({
	selection,
	onSelection,
	isConnected,
	roles,
}) => {
	const [open, setOpen] = useState(false);
	const selectionValue =
		selection?.role || (selection?.metadata?.isHandRaised ? "Raised Hand" : "");
	const onItemClick = useCallback(value => {
		onSelection(value);
		setOpen(false);
	}, []); //eslint-disable-line
	if (!isConnected) {
		return null;
	}
		// filter the "no show" participants
	const filteredRoles = roles.filter(role => hiddenRoles.indexOf(role) === -1);

	return (
		<Dropdown.Root open={open} onOpenChange={value => setOpen(value)}>
			<Dropdown.Trigger
				asChild
				data-testid="participant_list_filter"
				className="border-1 border-app-dropdown-border rounded-dropdown py-1 px-2"
				tabIndex={0}
			>
				<Flex align="center" justify="between" direction="row" className="min-w-[120px] ml-6">
					<div className={cn("text-sm", textEllipsis())}>
						{selectionValue || "Alle"}
					</div>
					<div className="ml-1 text-app-neutral-700">
						{open ? (
							<ChevronUpIcon width={14} height={14} />
						) : (
							<ChevronDownIcon width={14} height={14} />
						)}
					</div>
				</Flex>
			</Dropdown.Trigger>
			<Dropdown.Content
				align="start"
				sideOffset={8}
				className="h-auto max-h-96 shadow-md w-48"
			>
				<Item
					selected={!selection}
					title="Alle"
					onSelection={onItemClick}
					icon={<PeopleIcon />}
				/>
				<Item
					selected={selection?.metadata?.isHandRaised}
					title="Hand gehoben"
					onSelection={onItemClick}
					icon={<HandRaiseIcon />}
					value={{ metadata: { isHandRaised: true }, role: "" }}
				/>
				<Dropdown.ItemSeparator />
				{filteredRoles
					.map(role => (
						<Item
							key={role}
							selected={selectionValue === role}
							title={roleNamesMap[role]}
							value={{ metadata: { isHandRaised: false }, role }}
							onSelection={onItemClick}
						/>
					))}
			</Dropdown.Content>
		</Dropdown.Root>
	);
};

const Item = ({ selected, title, onSelection, value, icon }) => {
	return (
		<Dropdown.Item
			onClick={e => {
				e.preventDefault();
				onSelection(value);
			}}
		>
			<Flex align="left" className="flex" direction="row" justify="center">
				{icon && <span>{icon}</span>}
				<span className="ml-2">{title}</span>
				{selected && (
					<span>
						<CheckIcon widht={16} height={16} />
					</span>
				)}
			</Flex>
		</Dropdown.Item>
	);
};
