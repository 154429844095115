import * as React from 'react';
import { SVGProps } from 'react';

const SvgHandRaiseFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.193 14.89c.725 3.475 2.329 5.528 5.466 5.528 2.352 0 3.878-.956 4.927-2.876.748-1.195 1.272-2.837 1.704-4.255.23-.733.547-1.658.57-2.067.031-.462-.27-.793-.732-.824-.532-.039-.864.27-1.195.963l-1.08 2.32c-.1.232-.208.31-.331.31-.139 0-.24-.078-.24-.332l.356-8.144a.885.885 0 0 0-.894-.894.884.884 0 0 0-.887.894l-.355 5.923c-.2-.069-.416-.13-.64-.177l-.187-6.783a.885.885 0 0 0-.895-.894.884.884 0 0 0-.886.894l.187 6.698a9.593 9.593 0 0 0-.678.093l-.785-6.214a.882.882 0 0 0-.887-.887.884.884 0 0 0-.894.887l.72 6.72c-.232.107-.53.27-.731.401l-.908-4.716a.882.882 0 0 0-.886-.886.884.884 0 0 0-.895.886l1.056 7.432Z"
      fill="#FAC919"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.725 15.197a1.495 1.495 0 0 1-.017-.096L3.652 7.67a1.5 1.5 0 0 1-.015-.21 2.383 2.383 0 0 1 2.7-2.368v-.038A2.383 2.383 0 0 1 8.73 2.666c.432 0 .836.114 1.184.312a2.373 2.373 0 0 1 1.866-.896c.895 0 1.67.483 2.08 1.204a2.39 2.39 0 0 1 .883-.167 2.385 2.385 0 0 1 2.393 2.46l-.155 3.555c.389-.191.814-.266 1.25-.235a2.29 2.29 0 0 1 1.566.748c.424.474.596 1.078.56 1.665-.025.406-.165.903-.267 1.247-.082.276-.18.582-.268.858l-.1.314c-.418 1.372-.986 3.185-1.842 4.57-.62 1.122-1.43 2.05-2.504 2.689-1.087.645-2.335.928-3.718.928-1.936 0-3.536-.652-4.735-1.938-1.148-1.23-1.81-2.916-2.2-4.783Zm11.86 2.345c.749-1.195 1.273-2.837 1.705-4.255l.11-.348c.21-.656.44-1.372.46-1.719.031-.462-.27-.793-.732-.824-.532-.039-.864.27-1.195.963l-1.08 2.32v.003c-.1.23-.208.306-.331.306-.139 0-.24-.077-.24-.331l.356-8.144a.885.885 0 0 0-.894-.894.882.882 0 0 0-.887.894l-.355 5.923c-.2-.069-.416-.13-.64-.177l-.187-6.783a.884.884 0 0 0-.894-.894.88.88 0 0 0-.887.894l.187 6.698a9.593 9.593 0 0 0-.678.093l-.785-6.214a.886.886 0 0 0-.224-.592.883.883 0 0 0-.663-.295.884.884 0 0 0-.894.887l.72 6.72c-.232.107-.53.27-.731.401l-.908-4.716a.882.882 0 0 0-.886-.886.884.884 0 0 0-.895.886l1.056 7.432c.725 3.475 2.329 5.528 5.466 5.528 2.352 0 3.878-.956 4.927-2.876Z"
      fill="#212121"
    />
  </svg>
);

export default SvgHandRaiseFilledIcon;
