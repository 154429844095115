import {theme, styled} from 'twin.macro';
import {useTranslation} from "react-i18next";

const Link = styled.a({
	color: theme`colors.app.link`,
	"&:hover": {
		textDecoration: "underline dotted"
	}
});

const ErrorWithSupportLink = ({errorMessage}) => {
	const {t} = useTranslation()
	
	return (
		<div>
			<p>{errorMessage}</p>
			<p>
				<Link
					target="_blank"
					href="https://www.schauburg.net"
					rel="noreferrer noopener"
				>
					{t("Hier")}
				</Link>
				{" "}
				{t("kannst Du uns kontaktieren.")}
			</p>
		</div>
		)
	};

export default ErrorWithSupportLink;
