import React from 'react';
import { SVGProps } from 'react';

const SvgDoorOpenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="64px" height="64px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" /* Remove {...props} */>
    <path d="m34.85,33.8c.87-1.23.87-3.22,0-4.45s-2.29-1.23-3.16,0h0c-.87,1.23-.87,3.22,0,4.45s2.29,1.23,3.16,0h0Z"/>
  <path d="m56.05,58.42h-4.58V11.47c0-2.53-2.05-4.58-4.58-4.58h-4.04v-.82c0-1.58-.72-3.04-1.97-4s-2.84-1.29-4.37-.89l-20.16,5.28c-2.22.58-3.78,2.6-3.78,4.89v47.08h-4.62c-1.26,0-2.29,1.03-2.29,2.29s1.03,2.29,2.29,2.29h48.1c1.26,0,2.29-1.03,2.29-2.29s-1.03-2.29-2.29-2.29ZM17.1,11.34c0-.24.16-.45.4-.52l20.16-5.28c.05-.01.1-.02.14-.02.14,0,.25.06.32.11.09.07.21.21.21.42v45.75c0,.24-.16.45-.4.52l-20.83,5.45V11.34Zm29.79,47.08h-14.36l6.55-1.71h0c2.22-.58,3.78-2.6,3.78-4.89V11.47h4.04v46.95Z"/>
  </svg>
);

export default SvgDoorOpenIcon;
