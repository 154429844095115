import { Root } from '@radix-ui/react-dialog';
import {styled} from 'twin.macro';
import {
  DialogClose,
  DialogDefaultCloseIcon,
  DialogDescription,
  DialogTitle,
  StyledDialogContent,
  StyledDialogOverlay,
  StyledDialogPortal,
  StyledDialogTrigger,
} from './DialogContent';

const StyledDialogRoot = styled(Root)``;

export const StyledDialog = {
  Root: StyledDialogRoot,
  Trigger: StyledDialogTrigger,
  Overlay: StyledDialogOverlay,
  Content: StyledDialogContent,
  Title: DialogTitle,
  Description: DialogDescription,
  Close: DialogClose,
  DefaultClose: DialogDefaultCloseIcon,
  Portal: StyledDialogPortal,
};
