import * as BaseTooltip from '@radix-ui/react-tooltip';
import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import tw, {theme} from 'twin.macro';
import { slideDownAndFade, slideLeftAndFade, slideRightAndFade, slideUpAndFade } from './utils/animations';

const TooltipBox = styled(BaseTooltip.Content)([
	tw`bg-app-secondary-default text-app-primary-default z-500`,
	{
		fontFamily: theme`components.tooltip.fontFamily`,
		fontSize: theme`components.tooltip.fontSize`,
		fontWeight: theme`components.tooltip.fontWeight`,
		borderRadius: theme`components.tooltip.borderRadius`,
		padding: theme`components.tooltip.padding`,
		
		'@media (prefers-reduced-motion: no-preference)': {
			animationDuration: '400ms',
			animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
			willChange: 'transform, opacity',
			'&[data-state="delayed-open"]': {
				'&[data-side="top"]': { animationName: slideDownAndFade() },
				'&[data-side="right"]': { animationName: slideLeftAndFade() },
				'&[data-side="bottom"]': { animationName: slideUpAndFade() },
				'&[data-side="left"]': { animationName: slideRightAndFade() },
			},
		},
	},
]);

const TooltipTrigger = tw(BaseTooltip.Trigger)``;

const TooltipRoot = BaseTooltip.Root;
export type alignTooltip = 'end' | 'center' | 'start' | undefined;
export type sideTooltip = 'bottom' | 'left' | 'right' | 'top' | undefined;

export const Tooltip: React.FC<
	PropsWithChildren<{ title: React.ReactNode | string; side?: sideTooltip; align?: alignTooltip; className?:string }>
> = ({ children, title, side = 'bottom', align = 'center', className }) => (
	<BaseTooltip.Provider>
		<TooltipRoot delayDuration={300}>
			<TooltipTrigger asChild>{children}</TooltipTrigger>
			<TooltipBox sideOffset={10} side={side} align={align} className={className}>
				{title}
			</TooltipBox>
		</TooltipRoot>
	</BaseTooltip.Provider>
);
