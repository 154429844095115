import {create} from "zustand"
// import produce from 'immer'
import { devtools } from 'zustand/middleware'
import { ILocalStore } from "."
import {Role, RoleSpecifier} from "../lib/peerTypes"

// import { mountStoreDevtool } from "simple-zustand-devtools"


// export const localStore = create<IEventStore>((set, get) => {
const localStore = (set, get):ILocalStore => {
	const initialState = {
		displayName: null,
		ticketCode: null,
		email: null,
		avatarUrl: null,
		avatarActive: false,
		muteVideo: false,
		hasVideo: false,
		muteAudio: false,
		hasAudio: false,
		volume: 1,
		onStage: false,
		role: Role.Viewer,
		originalRole: Role.Viewer,
		roleSpecifier: null,
		mkPollVotes: {},
		// localProps: { // these properties are only for local settings of each pear (e.g. pinned by me)
		// 	pinned: false,
		// 	localAudioMute: false,
		// 	localVideoMute: false,
		// 	localVolume: 1
		// }
	}

	// const produceAndSet = (callback:(newState:ILocalStore)=>void, logName:string | "") => set(state => produce(state, newState => callback(newState)), false, logName)

	// const setPeerSelf = (thePeer:IPeer) => {
	// 	set({ peerSelf: thePeer }, false, 'setPeerSelf')
	// }

	const setDisplayName = (theName:string) => {
		set({ displayName: theName }, false, 'setDisplayName')
	}

	const setTicketCode = (theCode:string) => {
		set({ ticketCode: theCode }, false, 'setTicketCode')
	}
	
	const setEmail = (theEmail:string) => {
		set({ email: theEmail }, false, 'setEmail')
	}

	const setHasVideo = (newVal:boolean) => {
		set({ hasVideo: newVal }, false, "setHasVideo")
	}

	const setHasAudio = (newVal:boolean) => {
		set({ hasAudio: newVal }, false, "setHasAudio")
	}

	const setMuteVideo = (newVal:boolean) => {
		set({ muteVideo: newVal }, false, "setMuteVideo")
	}

	const setMuteAudio = (newVal:boolean) => {
		set({ muteAudio: newVal }, false, "setMuteAudio")
	}

	const setRole = (newRole:Role) => {
		set({ role: newRole }, false, "setRole")
	}

	const setOriginalRole = (newRole:Role) => {
		set({ originalRole: newRole }, false, "setOriginalRole")
	}
	
	const setRoleSpecifier = (newRoleSpec:RoleSpecifier) => {
		set({ roleSpecifier: newRoleSpec }, false, "setRoleSpecifier")
	}

	const setMKPollVotes = (newVotes:Record<string, Record<string, number>>) => {
		set({ mkPollVotes: newVotes }, false, "setMKPollVotes")
	}

	const addMKPollVote = (pollId:string, optionId:string) => {
		const curVotes = get().mkPollVotes;
		const newVotes = {
			...curVotes,
			[pollId]: {
				...curVotes[pollId],
				[optionId]: (curVotes[pollId]?.[optionId] || 0) + 1
			}
		};
		set({ mkPollVotes: newVotes }, false, "addMKPollVote");
	}

	const clearMKPollVoteId = (pollId:string) => {
		const curVotes = get().mkPollVotes;
		delete curVotes[pollId];
		set({ mkPollVotes: {...curVotes} }, false, "clearMKPollVoteId");
	}

	const clearAllMKPollVotes = () => {
		set({ mkPollVotes: {} }, false, "clearAllMKPollVotes");
	}


	return {
		...initialState,
		setDisplayName,
		setTicketCode,
		setEmail,
		setHasVideo,
		setHasAudio,
		setMuteVideo,
		setMuteAudio,
		setRole,
		setOriginalRole,
		setRoleSpecifier,
		setMKPollVotes,
		addMKPollVote,
		clearMKPollVoteId,
		clearAllMKPollVotes,
	}
}

const dtEnabled = process.env.NODE_ENV === "development"
export const useLocalStore = create<ILocalStore>()(devtools<ILocalStore>(localStore, {enabled: dtEnabled, name: "LocalStore"}))
