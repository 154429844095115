/** @type {import('tailwindcss').Config} */
// import {appTheme} from './src/theme/theme.tsx'
const defaultTheme = require('tailwindcss/defaultTheme')
const plugin = require('tailwindcss/plugin')
// const themeColors = require('./src/styles/theme').colors
// const typography = require('./src/styles/theme').typography
// const { withTV } = require('tailwind-variants/transformer');

module.exports ={
	content: ["./src/**/*.{js,jsx,ts,tsx,html}", "./public/index.html"],
	theme: {
		screens: {
			'xs': '480px',
			// => @media (min-width: 480px) { ... }
			'sm': '640px',
			// => @media (min-width: 640px) { ... }
			'md': '768px',
			// => @media (min-width: 768px) { ... }
			'lg': '1024px',
			// => @media (min-width: 1024px) { ... }
			'xl': '1280px',
			// => @media (min-width: 1280px) { ... }
			'2xl': '1536px',
			// => @media (min-width: 1536px) { ... }
		},
		fontFamily: {
			sans: ['theinhardt', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'],
			serif: ['Times New Roman', 'Times', 'Georgia', 'Merriweather', 'serif'],
			headlines: ['schauburg-bold', 'Roboto', 'Arial', 'Helvetica Neue', 'sans-serif'],
		},
		fontSize: {
			xs: '0.75rem',
			ms: '0.825rem',
			sm: '0.875rem',
			base: '1rem',
			md: '1.08rem',
			lg: '1.125rem',
			xl: '1.25rem',
			'2xl': '1.5rem',
			'3xl': '1.875rem',
			'4xl': '2.25rem',
			'5xl': '3rem',
			'6xl': '4rem',
		},
		lineHeight: {
			...defaultTheme.lineHeight,
			normal: '1.4',
			headings: '1.1'
		},
		// rotate: {
		// 	'-270': '-270deg',
		// 	'-210': '-210deg',
		// },
		extend: {
			colors: {
				app: {
					primary: {
						default: '#46009B',
						'50': '#A154FF',
						'100': '#963FFF',
						'200': '#7F16FF',
						'300': '#6B00ED',
						'400': '#5800C4',
						'500': '#46009B',
						'600': '#3A0082',
						'700': '#2F0068',
						'800': '#23004E',
						'900': '#180035',
						contrast: '#F4F4F4'
					},
					secondary: {
						default: '#F5DC00',
						'50': '#FFF7AE',
						'100': '#FFF599',
						'200': '#FFF070',
						'300': '#FFEC48',
						'400': '#FFE81F',
						'500': '#F5DC00',
						'600': '#D6C100',
						'700': '#B8A500',
						'800': '#998A00',
						'900': '#7B6E00',
						contrast: '#46009B'
					},
					tertiary: {
						default: '#FA82B4',
						'50': '#FEDAE9',
						'100': '#FDD0E3',
						'200': '#FCBDD7',
						'300': '#FCA9CC',
						'400': '#FB96C0',
						'500': '#FA82B4',
						'600': '#F84C94',
						'700': '#F61673',
						'800': '#CC085A',
						'900': '#960642',
						contrast: '#46009B'
					},
					neutral: {
						default: '#f4f4f4',
						'50': '#FBFBFB',
						'100': '#F4F4F4',
						'200': '#E0E0E0',
						'300': '#BFBFBF',
						'400': '#A6A6A6',
						'500': '#8C8C8C',
						'600': '#737373',
						'700': '#595959',
						'800': '#404040',
						'900': '#262626',
						contrast: '#46009B',
					},
					warning: {
						default: '#cb3258',
						'50': '#F0C4CF',
						'100': '#ECB4C2',
						'200': '#E493A7',
						'300': '#DC728D',
						'400': '#D45272',
						'500': '#CB3258',
						'600': '#9E2744',
						'700': '#711C31',
						'800': '#44111D',
						'900': '#17060A',
						contrast: '#e0e0e0',
					},
					border: '#b5b5b5',
					divider: '#b5b5b5',
					dialog: {
						bg: '#f4f4f4',
						text: '#46009B',
						border: '2px solid #b5b5b5'
					},
					surfaceNeutral: {
						bg: '#f4f4f4',
						text: '#46009B',
						border: '2px solid #b5b5b5'
					},
					button: {
						bg: '#e0e0e0',
						bg_dark: '#aaaaaa',
						bgHover: '#FA82B4',
						bgActive: '#FA82B4',
						bgSelected: '#A6A6A6',
						text: '#46009B',
						textHover: '#46009B',
						textActive: '#F4F4F4',
						hilite: '#FA82B4',
					},
					input: {
						bg: '#f6f6f6',
						text: '#46009B',
						textDim: '#9f7abc'
					},
					tab: {
						bg: '#e0e0e0',
						bgActive: '#46009B',
						text: '#46009B',
						textActive: '#F4F4F4',
						listBg: '#BFBFBF',
					},
					dropdown: {
						bg: '#e0e0e0',
						bgActive: '#46009B',
						text: '#46009B',
						textActive: '#F4F4F4',
						border: '#262626',
					},
					text: '#F4F4F4',
					textNeg: '#46009B',
					background: '#46009B',
					color1: '#46009B',
					color2: '#F5DC00',
					color3: '#FA82B4',
					color4: '#FF330A',
					color5: '#e84832',
					link: '#e84832',
					linkHover: '#e97667',
					label: '#46009B',
					labelNegative: '#F4F4F4',
					surface: '#e0e0e0',
					surfaceHilite: '#BFBFBF',
					inherit: 'inherit',
					eventBackground: '#000', //'#283033',
				}
			},
			fontSize: {
				app: {
					regular: '1rem',
					base: '1rem',
					large: '1.125rem',
					small: '0.875rem',
					button: '0.85rem'
				}
			},
			transitionDuration: {
				'800': '800ms',
				'900': '900ms'
			},
			spacing: {
				15: '3.75rem',
				17: '4.25rem',
				18: '4.5rem',
				19: '4.75rem',
				22: '5.5rem',
				26: '6.5rem',
				30: '7.5rem',
				34: '8.5rem',
				38: '9.5rem',
			},
			borderWidth: {
				1: "1px",
			},
			typography: {
				primaryFont: ['theinhardt', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'],
				secondayFont: ['schauburg-bold', 'Roboto', 'Arial', 'Helvetica Neue', 'sans-serif'],
				headline: {
					fontFamily: 'schauburg-bold, Roboto, Arial, Helvetica Neue, sans-serif',
					fontWeight: 'normal',
					textTransform: 'uppercase'
				},
				buttons: {
					fontFamily: 'schauburg-bold, Roboto, Arial, Helvetica Neue, sans-serif',
					fontWeight: 'normal',
				},
				text: {
					fontFamily: 'theinhardt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
					fontWeight: 'normal',
					textTransform: 'none'
				},
			},
			boxShadow: {
				button: '0 2px 3px -1px rgb(0 0 0 / 15%), 0 1px 3px 0 rgb(0 0 0 / 20%)',
				buttonIcon: '0 3px 4px -1px rgb(0 0 0 / 25%), 0 2px 2px -2px rgb(0 0 0 / 30%)',
				dropdown: '0 5px 8px rgba(0,0,0,0.18), 0 4px 4px rgba(0,0,0,0.2)',
				dropdownSub: '0 5px 8px rgba(0,0,0,0.18), 0 4px 4px rgba(0,0,0,0.2)',
				tab: 'none',
			},
			borderRadius: {
				button: '0',
				buttonIcon: '0',
				dropdown: '1px',
				tab: '0',
			},
			keyframes: {
				slideUpAndFadeKeys: {
					'0%': {opacity: 0, transform: 'translateY(2px)'},
					'100%': {opacity: 1, transform: 'translateY(0)'},
				},
				slideDownAndFadeKeys: {
					'0%': {opacity: 0, transform: 'translateY(-2px)'},
					'100%': {opacity: 1, transform: 'translateY(0)'},
				},
				slideLeftAndFadeKeys: {
					'0%': {opacity: 0, transform: 'translateX(2px)'},
					'100%': {opacity: 1, transform: 'translateX(0)'},
				},
				slideRightAndFadeKeys: {
					'0%': {opacity: 0, transform: 'translateX(2px)'},
					'100%': {opacity: 1, transform: 'translateX(0)'},
				},
				waveAni: {
					'0%, 100%': { transform: 'rotate(0deg)' },
					'50%': { transform: 'rotate(70deg)' },
        }
			},
			animation: {
				slideUpAndFade: 'slideUpAndFadeKeys 400ms',
				slideDownAndFade: 'slideDownAndFadeKeys 400ms',
				slideLeftAndFade: 'slideLeftAndFadeKeys 400ms',
				slideRightAndFade: 'slideRightAndFadeKeys 400ms',
				waving: 'waveAni 2s ease-in-out infinite',
			},
			components: {
				button: {
					common: {
						fontWeight: 'normal',
						textTransform: 'uppercase',
						lineHeight: 1.1,
						borderRadius: 0,
						outlineBorder: '1px solid',
					},
					regular: {
						fontSize: '0.9rem',
					},
					large: {
						fontSize: '1.1rem',
					},
					small: {
						fontSize: '0.75rem',
					},
					xs: {
						fontSize: '0.55rem',
					},
					primary: {
						background: 'colors.app.primary.default',
						color: 'colors.app.primary.contrast',
					},
					secondary: {
						background: 'colors.app.secondary.default',
						color: 'colors.app.secondary.contrast',
					},
					shadow: '0 2px 3px -1px rgb(0 0 0 / 15%), 0 1px 3px 0 rgb(0 0 0 / 20%)'
				},
				button_icon: {
					fontSize: '0.55rem',
					fontSize_ss: '0.48rem',
					fontSize_lg: '0.65rem',
					fontSize_lg_ss: '0.6rem',
					fontSize_sm: '0.37rem',
					fontSize_sm_ss: '0.32rem',
					textTransform: 'uppercase',
					shadow: '0 3px 4px -1px rgb(0 0 0 / 25%), 0 2px 2px -2px rgb(0 0 0 / 30%)'
				},
				label: {
					fontFamily: 'theinhardt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
					fontWeight: 'normal',
					textTransform: 'none',
					fontSize: '1rem'
				},
				input: {
					fontFamily: 'theinhardt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
					fontWeight: 'normal',
					textTransform: 'none',
					fontSize: '1.125rem',
					border: '2px solid transparent',
					borderError: '2px solid #FF330A',
					borderFocus: '2px solid #F5DC00',
					borderRadius: '0'
				},
				textfield: {
					fontFamily: 'theinhardt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
					fontWeight: 'normal',
					textTransform: 'none',
					fontSize: '1.125rem',
					background: '#E0E0E0',
					border: '2px solid transparent',
					borderError: '2px solid #FF330A',
					borderFocus: '2px solid #F5DC00',
					borderRadius: '0'
				},
				surface: {
					border: 'none',
					borderRadius: '3px',
					background: '#EDE9E5',
					color: '#46009B',
					boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.4), 0 1px 2px -1px rgb(0 0 0 / 0.4)',
				},
				dialog: {
					border: 'none',
					borderRadius: '3px',
					background: '#EDE9E5',
					color: '#46009B',
					boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.4), 0 1px 2px -1px rgb(0 0 0 / 0.4)',
				},
				tab: {
					border: 'none',
					borderRadius: '0',
					boxShadow: 'none',
				},
				dropdown: {
					border: 'none',
					borderRadius: '0',
				},
				popover: {
					border: 'none',
					borderRadius: '0',
					background: '#EDE9E5',
					color: '#46009B',
					boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.4), 0 1px 2px -1px rgb(0 0 0 / 0.4)',
				},
				tooltip: {
					fontFamily: 'theinhardt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
					fontWeight: 'normal',
					textTransform: 'none',
					fontSize: '0.8rem',
					padding: '0.25rem 0.5rem 0.15rem',
					borderRadius: '2px',
				},
				sidepane: {
					border: 'none',
					borderRadius: '0',
					background: '#F5DC00',
					color: '#46009B',
					boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.4), 0 1px 2px -1px rgb(0 0 0 / 0.4)',
				},
				videowrapper: {
					border: 'none',
					borderRadius: '0',
					background: '#333',
					boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.3), 0 1px 2px -1px rgb(0 0 0 / 0.4)',
				},
				divider: {
					width: "1px",
					horizontalWidth: "100%",
					verticalHeight: "25px",
				},
				videotile: {
					audioBorder: '#46009B',
					border: 'none',
					borderRadius: '0',
					background: '#262626',
					color: '#f4f4f4',
					indicatorcolor: '#46009B',
					indicatorbackground: '#A6A6A6',
					boxShadow: 'none',
					fontFamily: 'theinhardt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
					button: {
						color: '#f4f4f4',
						background: '#262626',
						borderRadius: '0',
						shadow: '0 3px 4px -1px rgb(0 0 0 / 25%), 0 2px 2px -2px rgb(0 0 0 / 30%)',
					},
				},
				sidebar: {
					background: '#f0f0f0',
					color: '#46009B',
					border: 'none',
					borderRadius: '0',
				},
				toast: {
					border: 'none',
					borderRadius: '3px',
					background: '#EDE9E5',
					hilite: '#46009B',
					color: '#46009B',
					boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.4), 0 1px 2px -1px rgb(0 0 0 / 0.4)',
				},
			},
			zIndex: {
				'100': '100',
				'200': '200',
				'300': '300',
				'400': '400',
				'500': '500',
				'1000': '1000',
			}
		},
		data: {
			dataActive: 'state="active"',
			dataOpen: 'state="open"',
			dataChecked: 'state="checked"',
			dataOrientHorizontal: 'orientation="horizontal"',
			dataSideTop: 'side="top"',
			dataSideBottom: 'side="bottom"',
			dataSideLeft: 'side="left"',
			dataSideRight: 'side="right"',
			stateChecked: 'state="checked"',
		},	
	},
	plugins: [
		plugin(function({addBase, theme}) {
			addBase({
				'h1': {
					fontSize: theme('fontSize.3xl'),
					[`@media (min-width: ${theme('screens.sm')})`]: {
						fontSize: theme('fontSize.4xl'),
					},
					[`@media (min-width: ${theme('screens.lg')})`]: {
						fontSize: theme('fontSize.5xl'),
					},
					fontFamily: theme('typography.headline.fontFamily'),
					fontWeight: theme('typography.headline.fontWeight'),
					textTransform: theme('typography.headline.textTransform'),
					lineHeight: theme('lineHeight.headings'),
					marginBottom: '.75rem',
				},
				'h2': {
					fontSize: theme('fontSize.2xl'),
					[`@media (min-width: ${theme('screens.sm')})`]: {
						fontSize: theme('fontSize.3xl'),
					},
					[`@media (min-width: ${theme('screens.lg')})`]: {
						fontSize: theme('fontSize.4xl'),
					},
					fontFamily: theme('typography.headline.fontFamily'),
					fontWeight: theme('typography.headline.fontWeight'),
					textTransform: theme('typography.headline.textTransform'),
					lineHeight: theme('lineHeight.headings'),
					marginBottom: '.75rem',
				},
				'h3': {
					fontSize: theme('fontSize.xl'),
					[`@media (min-width: ${theme('screens.sm')})`]: {
						fontSize: theme('fontSize.2xl'),
					},
					[`@media (min-width: ${theme('screens.lg')})`]: {
						fontSize: theme('fontSize.3xl'),
					},
					fontFamily: theme('typography.headline.fontFamily'),
					fontWeight: theme('typography.headline.fontWeight'),
					textTransform: theme('typography.headline.textTransform'),
					lineHeight: theme('lineHeight.headings'),
					marginBottom: '.75rem',
				},
				'h4': {
					fontSize: theme('fontSize.lg'),
					[`@media (min-width: ${theme('screens.sm')})`]: {
						fontSize: theme('fontSize.xl'),
					},
					[`@media (min-width: ${theme('screens.lg')})`]: {
						fontSize: theme('fontSize.2xl'),
					},
					fontFamily: theme('typography.headline.fontFamily'),
					fontWeight: theme('typography.headline.fontWeight'),
					textTransform: theme('typography.headline.textTransform'),
					lineHeight: theme('lineHeight.headings'),
					marginBottom: '.75rem',
				},
				'h5': {
					fontSize: theme('fontSize.sm'),
					[`@media (min-width: ${theme('screens.sm')})`]: {
						fontSize: theme('fontSize.md'),
					},
					[`@media (min-width: ${theme('screens.lg')})`]: {
						fontSize: theme('fontSize.lg'),
					},
					fontFamily: theme('typography.headline.fontFamily'),
					fontWeight: theme('typography.headline.fontWeight'),
					textTransform: theme('typography.headline.textTransform'),
					lineHeight: theme('lineHeight.headings'),
					marginBottom: '.75rem',
				},
				'h6': {
					fontSize: theme('fontSize.sm'),
					[`@media (min-width: ${theme('screens.sm')})`]: {
						fontSize: theme('fontSize.sm'),
					},
					[`@media (min-width: ${theme('screens.lg')})`]: {
						fontSize: theme('fontSize.md'),
					},
					fontFamily: theme('typography.headline.fontFamily'),
					fontWeight: theme('typography.headline.fontWeight'),
					textTransform: theme('typography.headline.textTransform'),
					lineHeight: theme('lineHeight.headings'),
					marginBottom: '.75rem',
				},
				'a.textlink': {
					color: theme('colors.app.link'),
					'&:hover': {
						color: theme('colors.app.linkHover'),
					}
				},
				'.border-default': {
					border: `1px solid ${theme('colors.app.neutral.800')}`
				},
				'.border-bottom': {
					borderBottom: `1px solid ${theme('colors.app.neutral.500')}`
				},
				'.border-dropdown': {
					border: `1px solid ${theme('colors.app.dropdown.border')}`
				},
				'.border-switch-base': {
					border: `2px solid ${theme('colors.app.neutral.700')}`,
				},
				'.border-switch-base-active': {
					border: `2px solid ${theme('colors.app.primary.default')}`,
				},
				'.rounded-switch-base': {
					borderRadius: '0.85rem',
				},
				'.rounded-switch-thumb': {
					borderRadius: '50%',
				},
				
			})
		}),
		plugin(function({addComponents, theme}) {
			addComponents({
				'.btn-text': {
					fontFamily: theme('typography.buttons.fontFamily'),
					fontWeight: theme('typography.buttons.fontWeight'),
					fontSize: theme('components.button.regular.fontSize'),
					lineHeight: theme('components.button.common.lineHeight'),
					textTransform: theme('components.button.common.textTransform'),
					padding: `${theme('spacing.3')} ${theme('spacing.8')}`,
					boxShadow: theme('components.button.shadow'),
					'&:disabled': {
						opacity: 0.7,
					},

					'&.large': {
						fontSize: theme('components.button.large.fontSize'),
						padding: `${theme('spacing.4')} ${theme('spacing.9')}`,
					},
					'&.small': {
						fontSize: theme('components.button.small.fontSize'),
						padding: `${theme('spacing.3')} ${theme('spacing.6')}`,
					},
					'&.xs': {
						fontSize: theme('components.button.xs.fontSize'),
						padding: `${theme('spacing.2')} ${theme('spacing.4')}`,
					},

					'&.neutral': {
						backgroundColor: theme('colors.app.button.bg'),
						color: theme('colors.app.button.text'),
						'&:not([disabled]):hover': {
							backgroundColor: theme('colors.app.button.bgHover'),
							color: theme('colors.app.button.textHover'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.button.bgActive'),
							color: theme('colors.app.button.textActive'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bg')}`,
							color: theme('colors.app.button.bg'),
							'&:not([disabled]):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bgHover')}`,
								color: theme('colors.app.button.bgHover'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bgActive')}`,
								color: theme('colors.app.button.bgActive'),
							},
						}
					},

					'&.neutral_dark': {
						backgroundColor: theme('colors.app.button.bg_dark'),
						color: theme('colors.app.button.text'),
						'&:not([disabled]):hover': {
							backgroundColor: theme('colors.app.button.bgHover'),
							color: theme('colors.app.button.textHover'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.button.bgActive'),
							color: theme('colors.app.button.textActive'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bg_dark')}`,
							color: theme('colors.app.button.bg_dark'),
							'&:not([disabled]):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bgHover')}`,
								color: theme('colors.app.button.bgHover'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bgActive')}`,
								color: theme('colors.app.button.bgActive'),
							},
						}
					},

					'&.primary': {
						backgroundColor: theme('colors.app.primary.default'),
						color: theme('colors.app.primary.contrast'),
						'&:not([disabled]):hover': {
							backgroundColor: theme('colors.app.primary.300'),
							color: theme('colors.app.primary.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.primary.700'),
							color: theme('colors.app.neutral.400'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.primary.default')}`,
							color: theme('colors.app.primary.default'),
							'&:not([disabled]):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.primary.300')}`,
								color: theme('colors.app.primary.300'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.primary.700')}`,
								color: theme('colors.app.primary.700'),
							},
						},
					},

					'&.secondary': {
						backgroundColor: theme('colors.app.secondary.default'),
						color: theme('colors.app.secondary.contrast'),
						'&:not([disabled]):hover': {
							backgroundColor: theme('colors.app.secondary.200'),
							color: theme('colors.app.secondary.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.secondary.700'),
							color: theme('colors.app.primary.400'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.secondary.default')}`,
							color: theme('colors.app.secondary.default'),
							'&:not([disabled]):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.secondary.200')}`,
								color: theme('colors.app.secondary.100'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.secondary.600')}`,
								color: theme('colors.app.secondary.600'),
							},
						},
					},

					'&.tertiary': {
						backgroundColor: theme('colors.app.tertiary.default'),
						color: theme('colors.app.tertiary.contrast'),
						'&:not([disabled]):hover': {
							backgroundColor: theme('colors.app.tertiary.200'),
							color: theme('colors.app.tertiary.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.tertiary.600'),
							color: theme('colors.app.primary.400'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.tertiary.default')}`,
							color: theme('colors.app.tertiary.default'),
							'&:not([disabled]):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.tertiary.200')}`,
								color: theme('colors.app.tertiary.200'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.tertiary.600')}`,
								color: theme('colors.app.tertiary.600'),
							},
						},
					},

					'&.warning': {
						backgroundColor: theme('colors.app.warning.default'),
						color: theme('colors.app.warning.contrast'),
						'&:not([disabled]):hover': {
							backgroundColor: theme('colors.app.warning.400'),
							color: theme('colors.app.warning.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.warning.600'),
							color: theme('colors.app.warning.contrast'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.warning.default')}`,
							color: theme('colors.app.warning.default'),
							'&:not([disabled]):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.warning.400')}`,
								color: theme('colors.app.warning.400'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.warning.600')}`,
								color: theme('colors.app.warning.600'),
							},
						}
					},

					'&.icon': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					},
				},
				'btn-typo': {
					fontFamily: theme('typography.buttons.fontFamily'),
					fontWeight: theme('typography.buttons.fontWeight'),
					fontSize: theme('components.button_icon.fontSize_ss'),
					lineHeight: theme('components.button.common.lineHeight'),
					textTransform: theme('components.button_icon.textTransform'),
				},
				'.btn-icon': {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					fontFamily: theme('typography.buttons.fontFamily'),
					fontWeight: theme('typography.buttons.fontWeight'),
					fontSize: theme('components.button_icon.fontSize_ss'),
					lineHeight: theme('components.button.common.lineHeight'),
					textTransform: theme('components.button_icon.textTransform'),
					boxShadow: theme('components.button_icon.shadow'),
					padding: theme('spacing.3'),
					width: theme('spacing.12'),
					height: theme('spacing.10'),
					'&:disabled': {
						opacity: 0.5,
					},
					'&.no-shadow': {
						boxShadow: 'none',
					},
					'&.nofill': {
						background: 'none !important',
						color: `${theme('colors.app.text')} !important`,
					},
					[`@media (min-width: ${theme('screens.lg')})`]: {
						fontSize: theme('components.button_icon.fontSize'),
						width: theme('spacing.15'),
						height: theme('spacing.12'),
					},
					'& svg, & img': {
						width: '100%',
						height: 'auto',
					},
					'& span': {
						flexGrow: 0,
						marginTop: theme('spacing.1'),
						[`@media (min-width: ${theme('screens.lg')})`]: {
							marginTop: theme('spacing[1.5]'),
						},
					},
					'&.square': {
						padding: theme('spacing.1'),
						width: theme('spacing.12'),
						height: theme('spacing.12'),
						[`@media (min-width: ${theme('screens.lg')})`]: {
							width: theme('spacing.12'),
							height: theme('spacing.12'),
						},
						'&.with-text': {
							padding: theme('spacing.2'),
							width: theme('spacing.16'),
							height: theme('spacing.16'),
							[`@media (min-width: ${theme('screens.lg')})`]: {
								width: theme('spacing.20'),
								height: theme('spacing.20'),
							},
						},
					},
					'&.with-text': {
						padding: theme('spacing.2'),
						width: theme('spacing.14'),
						height: theme('spacing.18'),
						[`@media (min-width: ${theme('screens.lg')})`]: {
							width: theme('spacing.18'),
							height: theme('spacing.22'),
						},
					},

					'&.large': {
						fontSize: theme('components.button_icon.fontSize_lg_ss'),
						padding: theme('spacing.4'),
						width: theme('spacing.14'),
						height: theme('spacing.10'),
						[`@media (min-width: ${theme('screens.lg')})`]: {
							fontSize: theme('components.button_icon.fontSize_lg'),
							width: theme('spacing.18'),
							height: theme('spacing.14'),
						},
						'&.square': {
							padding: theme('spacing.2'),
							width: theme('spacing.14'),
							height: theme('spacing.14'),
							[`@media (min-width: ${theme('screens.lg')})`]: {
								width: theme('spacing.16'),
								height: theme('spacing.16'),
							},
							'&.with-text': {
								padding: theme('spacing.3'),
								width: theme('spacing.16'),
								height: theme('spacing.16'),
								[`@media (min-width: ${theme('screens.lg')})`]: {
									padding: theme('spacing.4'),
									width: theme('spacing.18'),
									height: theme('spacing.18'),
								},
							},
						},
						'&.with-text': {
							padding: theme('spacing.2'),
							width: theme('spacing.16'),
							height: theme('spacing.12'),
							[`@media (min-width: ${theme('screens.lg')})`]: {
								width: theme('spacing.20'),
								height: theme('spacing.16'),
							},
						},
					},
					'&.small': {
						fontSize: theme('components.button_icon.fontSize_sm_ss'),
						padding: theme('spacing.2'),
						width: theme('spacing.11'),
						height: theme('spacing.8'),
						'& span': {
							marginTop: theme('spacing[0.5]'),
						},
						[`@media (min-width: ${theme('screens.lg')})`]: {
							fontSize: theme('components.button_icon.fontSize_sm'),
							padding: theme('spacing[2.5]'),
							width: theme('spacing.14'),
							height: theme('spacing.10'),
						},
						'&.square': {
							padding: theme('spacing.1'),
							width: theme('spacing.8'),
							height: theme('spacing.8'),
							[`@media (min-width: ${theme('screens.lg')})`]: {
								width: theme('spacing.10'),
								height: theme('spacing.10'),
							},
							'&.with-text': {
								padding: theme('spacing.1'),
								width: theme('spacing.10'),
								height: theme('spacing.10'),
								'& span': {
									marginTop: 0,
								},
								[`@media (min-width: ${theme('screens.lg')})`]: {
									width: theme('spacing.12'),
									height: theme('spacing.12'),
								},
							},
						},
						'&.with-text': {
							padding: theme('spacing.1'),
							width: theme('spacing.10'),
							height: theme('spacing.8'),
							[`@media (min-width: ${theme('screens.lg')})`]: {
								width: theme('spacing.12'),
								height: theme('spacing.14'),
							},
						},
					},
					'&.xs': {
						fontSize: theme('components.button_icon.fontSize_sm_ss'),
						padding: theme('spacing.2'),
						width: theme('spacing.9'),
						height: theme('spacing.7'),
						'& span': {
							marginTop: theme('spacing[0.5]'),
						},
						[`@media (min-width: ${theme('screens.lg')})`]: {
							fontSize: theme('components.button_icon.fontSize_sm'),
							padding: theme('spacing[1.5]'),
							width: theme('spacing.10'),
							height: theme('spacing.8'),
						},
						'&.square': {
							padding: theme('spacing.1'),
							width: theme('spacing.7'),
							height: theme('spacing.7'),
							[`@media (min-width: ${theme('screens.lg')})`]: {
								width: theme('spacing.8'),
								height: theme('spacing.8'),
							},
							'&.with-text': {
								padding: theme('spacing.1'),
								width: theme('spacing.8'),
								height: theme('spacing.18'),
								'& span': {
									marginTop: 0,
								},
								[`@media (min-width: ${theme('screens.lg')})`]: {
									width: theme('spacing.10'),
									height: theme('spacing.10'),
								},
							},
						},
						'&.with-text': {
							padding: theme('spacing.1'),
							width: theme('spacing.8'),
							height: theme('spacing.9'),
							[`@media (min-width: ${theme('screens.lg')})`]: {
								width: theme('spacing.10'),
								height: theme('spacing.11'),
							},
						},
					},

					'&.neutral': {
						backgroundColor: theme('colors.app.button.bg'),
						color: theme('colors.app.button.text'),
						'&:not([disabled], .selected):hover': {
							backgroundColor: theme('colors.app.button.bgHover'),
							color: theme('colors.app.button.textHover'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.button.bgActive'),
							color: theme('colors.app.button.textActive'),
						},
						'&.active': {
							backgroundColor: theme('colors.app.button.bgSelected'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bg')}`,
							color: theme('colors.app.button.bg'),
							'&:not([disabled], .selected):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bgHover')}`,
								color: theme('colors.app.button.bgHover'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.button.bgActive')}`,
								color: theme('colors.app.button.bgActive'),
							},
						}
					},

					'&.primary': {
						backgroundColor: theme('colors.app.primary.default'),
						color: theme('colors.app.primary.contrast'),
						'&:not([disabled], .selected):hover': {
							backgroundColor: theme('colors.app.primary.300'),
							color: theme('colors.app.primary.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.primary.700'),
							color: theme('colors.app.neutral.400'),
						},
						'&.active': {
							backgroundColor: theme('colors.app.primary.400'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.primary.default')}`,
							color: theme('colors.app.primary.default'),
							'&:not([disabled], .selected):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.primary.300')}`,
								color: theme('colors.app.primary.300'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.primary.700')}`,
								color: theme('colors.app.primary.700'),
							},
						},
					},

					'&.secondary': {
						backgroundColor: theme('colors.app.secondary.default'),
						color: theme('colors.app.secondary.contrast'),
						'&:not([disabled], .selected):hover': {
							backgroundColor: theme('colors.app.secondary.200'),
							color: theme('colors.app.secondary.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.secondary.700'),
							color: theme('colors.app.primary.400'),
						},
						'&.active': {
							backgroundColor: theme('colors.app.secondary.600'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.secondary.default')}`,
							color: theme('colors.app.secondary.default'),
							'&:not([disabled], .selected):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.secondary.200')}`,
								color: theme('colors.app.secondary.100'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.secondary.600')}`,
								color: theme('colors.app.secondary.600'),
							},
						},
					},

					'&.tertiary': {
						backgroundColor: theme('colors.app.tertiary.default'),
						color: theme('colors.app.tertiary.contrast'),
						'&:not([disabled], .selected):hover': {
							backgroundColor: theme('colors.app.tertiary.200'),
							color: theme('colors.app.tertiary.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.tertiary.600'),
							color: theme('colors.app.primary.400'),
						},
						'&.active': {
							backgroundColor: theme('colors.app.tertiary.400'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.tertiary.default')}`,
							color: theme('colors.app.tertiary.default'),
							'&:not([disabled]):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.tertiary.200')}`,
								color: theme('colors.app.tertiary.200'),
							},
							'&:not([disabled], .selected):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.tertiary.600')}`,
								color: theme('colors.app.tertiary.600'),
							},
						},
					},

					'&.warning': {
						backgroundColor: theme('colors.app.warning.default'),
						color: theme('colors.app.warning.contrast'),
						'&:not([disabled], .selected):hover': {
							backgroundColor: theme('colors.app.warning.400'),
							color: theme('colors.app.warning.contrast'),
						},
						'&:not([disabled]):active': {
							backgroundColor: theme('colors.app.warning.600'),
							color: theme('colors.app.warning.contrast'),
						},
						'&.active': {
							backgroundColor: theme('colors.app.warning.400'),
						},
						'&.btnoutline': {
							backgroundColor: 'transparent',
							border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.warning.default')}`,
							color: theme('colors.app.warning.default'),
							'&:not([disabled], .selected):hover': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.warning.400')}`,
								color: theme('colors.app.warning.400'),
							},
							'&:not([disabled]):active': {
								backgroundColor: 'transparent',
								border: `${theme('components.button.common.outlineBorder')} ${theme('colors.app.warning.600')}`,
								color: theme('colors.app.warning.600'),
							},
						}
					},
				},

				// '.sidepane-right': {
				// 	position: "fixed",
				// 	width: "100%",
				// 	height: "100%",
				// 	padding: theme('spacing.3'),
				// 	bg: "$surfaceDefault",
				// 	borderRadius: 2,
				// 	top: 0,
				// 	right: 0,
				// 	zIndex: 10,
				// 	boxShadow: "$md",
				// 	'@media (min-width: 480px)': {
				// 		position: "absolute",
				// 		width: theme('spacing.96'),
				// 		height: "100%",
				// 		right: theme('spacing.2'),
				// 		bottom: 0,
				// 	},
				// },
				'.headline-font': {
					fontFamily: theme('typography.headline.fontFamily'),
				},
				'.videotile, .dummytile': {
					transition: 'left 700ms ease-out, right 700ms ease-out, width 700ms ease-out, height 700ms ease-out, opacity 700ms',
					'&.centered': {
						transform: 'translateX(-50%) translateY(-50%)',
					},
					'& video': {
						width: "100%",
					},
					'& img': {
						height: "100%",
						minHeight: "90px",
					},
					'& .round': {
						borderRadius: "50%",
						// overflow: "hidden",
					},
					'& .rounded': {
						borderRadius: "1.5rem",
						// overflow: "hidden",
					},
				},
				'.popover-animation': {
					'@media (prefers-reduced-motion: no-preference)': {
						animationDuration: '400ms',
						animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
						willChange: 'transform, opacity',
					},
				},
			})
		}),
		plugin(({ addVariant }) => {
  		addVariant('dataActive', '&[data-state="active"]');
  		addVariant('dataOpen', '&[data-state="open"]');
  		addVariant('dataChecked', '&[data-state="checked"]');
  		addVariant('dataOrientHorizontal', '&[data-orientation="horizontal"]');
  		addVariant('dataSideTop', '&[data-side="top"]');
  		addVariant('dataSideBottom', '&[data-side="bottom"]');
  		addVariant('dataSideLeft', '&[data-side="left"]');
  		addVariant('dataSideRight', '&[data-side="right"]');
  	}),
	]
};
