import React from "react";
// import { selectAppData } from "@100mslive/react-sdk";
import {
  ChatIcon,
  ConnectivityIcon,
  HandIcon,
  PersonIcon,
  PoorConnectivityIcon,
} from "../../styles/AppIcons";
import { Button } from "../../mkui";
import {
  useIsSidepaneTypeOpen,
  useSidepaneToggle,
} from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../lib/constants";

// const isChatOpen = () => {
//   return (
//     hmsStore.getState(selectAppData(APP_DATA.sidePane)) ===
//     SIDE_PANE_OPTIONS.CHAT
//   );
// };

const ChatAction = React.forwardRef((_, ref) => {
  const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);
  const isChatOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.CHAT);

  if (isChatOpen) {
    return null;
  }

  return (
    <Button
      color="neutral"
      size="xs"
      className="max-w-max"
      onClick={toggleChat}
      ref={ref}
    >
      Chat öffnen
    </Button>
  );
});

export const ToastConfig = {
  PEER_LIST: {
    single: function (notification) {
      if (notification.data.length === 1) {
        return {
          title: `${notification.data[0]?.name} ist gekommen`,
          icon: <PersonIcon />,
        };
      }
      return {
        title: `${notification.data[notification.data.length - 1]?.name} und ${
          notification.data.length - 1
        } weitere sind gekommen`,
        icon: <PersonIcon />,
      };
    },
    multiple: notifications => {
      return {
        title: `${notifications[0].data.name} und ${
          notifications.length - 1
        } weitere sind gekommen`,
        icon: <PersonIcon />,
      };
    },
  },
  PEER_JOINED: {
    single: function (notification) {
      return {
        title: `${notification.data?.name} ist gekommen`,
        icon: <PersonIcon />,
      };
    },
    multiple: function (notifications) {
      return {
        title: `${notifications[notifications.length - 1].data.name} und ${
          notifications.length - 1
        } weitere sind gekommen`,
        icon: <PersonIcon />,
      };
    },
  },
  PEER_LEFT: {
    single: function (notification) {
      return {
        title: `${notification.data?.name} ist gegangen`,
        icon: <PersonIcon />,
      };
    },
    multiple: function (notifications) {
      return {
        title: `${notifications[notifications.length - 1].data.name} und ${
          notifications.length - 1
        } weitere sind gegangen`,
        icon: <PersonIcon />,
      };
    },
  },
  METADATA_UPDATED: {
    single: notification => {
      return {
        title: `${notification.data?.name} hat die Hand gehoben`,
        icon: <HandIcon />,
      };
    },
    multiple: notifications => {
      return {
        title: `${notifications[notifications.length - 1].data?.name} und ${
          notifications.length - 1
        } weitere haben die Hand gehoben`,
        icon: <HandIcon />,
      };
    },
  },
  NEW_MESSAGE: {
    single: notification => {
      return {
        title: `Neue Nachricht von ${notification.data?.senderName}`,
        icon: <ChatIcon />,
        action: <ChatAction />,
        //duration: 60000, // for testing
      };
    },
    multiple: notifications => {
      return {
        title: `${notifications.length} neue Nachrichten`,
        icon: <ChatIcon />,
        action: <ChatAction />,
      };
    },
  },
  RECONNECTED: {
    single: () => {
      return {
        title: 'Die Verbindung wurde wieder hergestellt.',
        icon: <ConnectivityIcon />,
        variant: "success",
        duration: 3000,
      };
    },
  },
  RECONNECTING: {
    single: message => {
      return {
        title: `Die Verbindung wurde unterbrochen. Während wir versuchen erneut zu verbinden, prüfe bitte den Internetanschluss. ${message}.
      `,
        icon: <PoorConnectivityIcon />,
        variant: "warning",
        duration: 7000,
      };
    },
  },
};
