import * as BaseSwitch from '@radix-ui/react-switch';
import React from 'react';
import tw from 'twin.macro';

const SwitchRoot = tw(BaseSwitch.Root)`
	relative w-[3.7rem] h-[1.7rem] p-1 border-switch-base bg-app-neutral-200 rounded-switch-base cursor-pointer focus:outline-none dataChecked:bg-app-primary-default dataChecked:border-switch-base-active disabled:opacity-50 disabled:cursor-not-allowed
`;

const SwitchThumb = tw(BaseSwitch.Thumb)`
	block top-[-.3px] absolute w-6 h-6 bg-app-neutral-700 rounded-switch-thumb left-[0.5px] dataChecked:left-[1.95rem] dataChecked:bg-app-secondary-500 transition-[left] ease-in duration-300
`;

type SwitchProps = React.ComponentProps<typeof SwitchRoot> & {css?: any;};

export const Switch: React.FC<SwitchProps> = props => (
  <SwitchRoot {...props}>
    <SwitchThumb />
  </SwitchRoot>
);

Switch.displayName = 'Switch';
