import * as React from 'react';
import { SVGProps } from 'react';

const SvgEyeOpenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.93 12.28a12.813 12.813 0 0 1-.324-.53 14.011 14.011 0 0 1 1.848-2.487C7.796 7.83 9.673 6.5 12 6.5c2.325 0 4.203 1.33 5.547 2.763a14.013 14.013 0 0 1 1.848 2.487 14.013 14.013 0 0 1-1.848 2.487C16.203 15.67 14.325 17 12 17c-2.325 0-4.203-1.33-5.547-2.763a14.011 14.011 0 0 1-1.522-1.956Zm15.32-.53.67-.336-.67.336Zm.67-.336a.751.751 0 0 1 0 .671l-.67-.335c.67.335.67.336.67.337v.001l-.003.006-.009.016-.03.058a14.008 14.008 0 0 1-.549.926c-.38.59-.947 1.378-1.688 2.169-1.47 1.567-3.716 3.237-6.64 3.237-2.926 0-5.173-1.67-6.642-3.237a15.508 15.508 0 0 1-2.124-2.887 9.417 9.417 0 0 1-.143-.266l-.009-.016-.002-.006-.002-.002.671-.336-.67.335a.75.75 0 0 1 0-.67l.67.335c-.67-.335-.67-.336-.67-.337v-.001l.003-.005.009-.017a5.328 5.328 0 0 1 .143-.266A15.51 15.51 0 0 1 5.36 8.237C6.83 6.67 9.075 5 12 5s5.172 1.67 6.641 3.237a15.508 15.508 0 0 1 2.124 2.887c.05.088.087.159.113.208l.03.058.009.017.002.005.002.002Zm-10.42.336a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEyeOpenIcon;
