import {IEventConfig} from '../store';
import {Role, RoleSpecifier} from "./peerTypes";
import { ViewLayout, EventView } from "./layoutDefs";
import qs from 'qs';

export enum EventId {
	Workshop1 = 11,
	Workshop2 = 12,
	
	Conference = 30,

	Erika = 50,
};


export enum EventBackground {
	black = "#000",
	darkgray = "#283033",
	midgray = "#343638",
	schauburg = "#46009B",
	workshop1 = "#283033",
};

export enum EventType {
	undefined = "undef",
	stage = "stage",
	workshop = "workshop",
	conference = "conference",
};


const defaultConfig:IEventConfig = {
	// TODO fix room id for development
	conferenceRoomId: process.env.REACT_APP_DEFAULT_ROOM_ID || "6384b0beaee54625da6434c4",
	eventType: EventType.stage,
	availableEventRooms: ["stage"],
	availableRoles: [Role.Actor, Role.Audience, Role.Director, Role.Commander, Role.Videoplayer, Role.Audioplayer, Role.Viewer],
	availableRoleSpecifiers: [RoleSpecifier.Actor1, RoleSpecifier.Actor2, RoleSpecifier.Actor3],
	videoAspectWidth: 1,
	videoAspectHeight: 1,
	eventBackgroundColor: EventBackground.black,
	allowVirtualBackground: false,
	allowNoiseReduction: false,
	showToolbar: true,
	showTitlebar: true,
	autoHideControls: false,
	useChat: false,
	useApplause: true,
	useConfetti: true,
	usePolls: false,
	allowScreensharing: false,
	allowRecording: false,
	allowStreaming: false,
	initialEventView: EventView.StageView,
	initialEventLayout: ViewLayout.ActDScAsp__Aud1Rt_Aud2Rb,
	hiliteDominantSpeaker: false,
	hidePeerNames: false,
	useMetaActions: false,
	allowWhiteboard: false,
	allowUrlSharing: false,
	allowTranscription: false,
	useSignLanguageTranslation: false,
	useSubtitles: false,
	subtitleSourceLanguage: 'auto',
	subtitleLanguages: ['de', 'en-US'],
	allowPinning: false,
};

export function getEventConfiguration(eventId:string):Promise<IEventConfig> {
	return new Promise(async (resolve, reject) => {
		let eventConfig:IEventConfig = {...defaultConfig};
		let overrides = {};

		if (eventId.length === 6) {
			// cms id
			let query:Record<string, any> = {
				eventid: {
					equals: eventId,
				}
			}
			const stringifiedQuery = qs.stringify({
				// depth: 3, // fetches the entry including all its linked entries, too
				where: query,
			},
			{ addQueryPrefix: true }
		)
			let cmsHost = process.env.REACT_APP_CMS_HOST || "https://manage.schauburg.online"
			cmsHost += "/api/event"
			const res = await fetch(`${cmsHost}${stringifiedQuery}`)
			const myInfo = await res.json()
			// const myInfo = evtInfos.docs.filter((theDoc) => theDoc.eventid === eventId);
			if (myInfo && Array.isArray(myInfo.docs) && myInfo.docs.length > 0) {
				const myEvent = myInfo.docs[0];
				overrides = {
					availableRoles: [Role.Actor, Role.Audience, Role.Director, Role.Commander, Role.Moderator, Role.Presenter, Role.Viewer],
					eventType: myEvent.eventtype,
					availableRoleSpecifiers: [RoleSpecifier.Actor1, RoleSpecifier.Actor2],
					videoAspectWidth: 1,
					videoAspectHeight: 1,
					eventBackgroundColor: EventBackground[myEvent.bgcolor],
					allowVirtualBackground: myEvent.allowVirtualBackground,
					allowNoiseReduction: myEvent.allowNoiseReduction,
					showToolbar: myEvent.showToolbar,
					showTitlebar: true,
					autoHideControls: myEvent.autoHideControls,
					useChat: myEvent.useChat,
					useApplause: myEvent.useApplause,
					useConfetti: myEvent.useConfetti,
					usePolls: myEvent.usePolls || false,
					allowScreensharing: myEvent.allowScreensharing,
					allowRecording: myEvent.allowRecording,
					allowStreaming: myEvent.allowStreaming,
					initialEventView: EventView.WSGridView,
					initialEventLayout: ViewLayout.Wshp_ExpCenter__AudSides,
					hiliteDominantSpeaker: myEvent.hiliteDominantSpeaker,
					hidePeerNames: myEvent.hidePeerNames,
					useMetaActions: myEvent.useMetaActions,
					allowWhiteboard: myEvent.allowWhiteboard,
					allowUrlSharing: myEvent.allowUrlSharing,
					allowTranscription: myEvent.allowTranscription,
					useSignLanguageTranslation: false,
					useSubtitles: myEvent.useSubtitles || false,
					subtitleSourceLanguage: myEvent.subtitleSourceLanguage || 'auto',
					subtitleLanguages: myEvent.subtitleLanguages || [],
					allowPinning: true,
				};
			}
		} else if (eventId.length === 2) {
		 // for testing
			switch (parseInt(eventId, 10)) {
				case EventId.Workshop1:
				case EventId.Workshop2:
					overrides = {
						availableRoles: [Role.Actor, Role.Audience, Role.Director, Role.Commander, Role.Moderator, Role.Videoplayer, Role.Audioplayer, Role.Presenter, Role.Viewer],
						eventType: EventType.workshop,
						availableRoleSpecifiers: [RoleSpecifier.Actor1, RoleSpecifier.Actor2],
						videoAspectWidth: 1,
						videoAspectHeight: 1,
						eventBackgroundColor: EventBackground.workshop1,
						allowVirtualBackground: false,
						allowNoiseReduction: false,
						showToolbar: true,
						showTitlebar: true,
						autoHideControls: false,
						useChat: true,
						useApplause: true,
						useConfetti: true,
						usePolls: true,
						allowScreensharing: true,
						allowRecording: true,
						allowStreaming: false,
						initialEventView: EventView.StageView,
						initialEventLayout: ViewLayout.Wshp_ExpCenter__AudSides,
						hiliteDominantSpeaker: true,
						hidePeerNames: false,
						useMetaActions: true,
						allowWhiteboard: true,
						allowUrlSharing: true,
						allowTranscription: false,
						useSignLanguageTranslation: false,
						useSubtitles: true,
						subtitleSourceLanguage: 'de',
						subtitleLanguages: ['de', 'en-US', 'fr', 'it', 'es', 'pl'],
						allowPinning: true,
					};
					break;

				case EventId.Conference:
					overrides = {
						availableRoles: [Role.Audience, Role.Commander, Role.Moderator, Role.Presenter, Role.Viewer],
						eventType: EventType.conference,
						availableRoleSpecifiers: [],
						videoAspectWidth: 1,
						videoAspectHeight: 1,
						eventBackgroundColor: EventBackground.darkgray,
						allowVirtualBackground: true,
						allowNoiseReduction: false,
						showToolbar: true,
						showTitlebar: true,
						autoHideControls: false,
						useChat: true,
						useApplause: true,
						useConfetti: true,
						usePolls: true,
						allowScreensharing: true,
						allowRecording: true,
						allowStreaming: false,
						initialEventView: EventView.StageView,
						initialEventLayout: ViewLayout.Wshp_ExpCenter__AudSides,
						hiliteDominantSpeaker: true,
						hidePeerNames: false,
						useMetaActions: true,
						allowWhiteboard: true,
						allowUrlSharing: false,
						allowTranscription: false,
						useSignLanguageTranslation: false,
						useSubtitles: false,
						subtitleSourceLanguage: 'de',
						subtitleLanguages: ['de', 'en-US'],
						allowPinning: true,
					};
					break;

				case EventId.Erika:
					overrides = {
						eventType: EventType.stage,
						availableEventRooms: ["stage"],
						availableRoles: [Role.Audience, Role.Commander, Role.Moderator, Role.Presenter, Role.Viewer, Role.Videoplayer, Role.Audioplayer],
						availableRoleSpecifiers: [RoleSpecifier.Actor1, RoleSpecifier.Actor2],
						videoAspectWidth: 1,
						videoAspectHeight: 1,
						eventBackgroundColor: EventBackground.schauburg,
						allowVirtualBackground: false,
						allowNoiseReduction: false,
						showToolbar: true,
						showTitlebar: true,
						autoHideControls: false,
						useChat: false,
						useApplause: true,
						useConfetti: true,
						usePolls: false,
						allowScreensharing: false,
						allowRecording: false,
						allowStreaming: false,
						initialEventView: EventView.StageView,
						initialEventLayout: ViewLayout.ActDScAsp__Aud1Rt_Aud2Rb,
						hiliteDominantSpeaker: false,
						hidePeerNames: false,
						useMetaActions: false,
						allowWhiteboard: false,
						allowUrlSharing: false,
						allowTranscription: false,
						useSignLanguageTranslation: false,
						useSubtitles: false,
						subtitleSourceLanguage: '',
						subtitleLanguages: [],
						allowPinning: false,
					};
					break;

				default:
					overrides = {}
			}
		}

		resolve(Object.assign(eventConfig, overrides));
	})
}
