import React from "react";
// import * as ReactDOM from "react-dom"
import { createRoot } from "react-dom/client";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
// import { init } from "zipyai";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import './i18n/i18n';
import "./index.css";

if (
	process.env.NODE_ENV === "production" &&
	process.env.REACT_APP_LOGROCKET_ID
) {
	LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
	setupLogRocketReact(LogRocket);
}

// if (process.env.REACT_APP_ZIPY_KEY) {
// 	init(process.env.REACT_APP_ZIPY_KEY);
// }

async function checkResizeObserver() {
	if (!window.ResizeObserver) {
		window.ResizeObserver = (await import('resize-observer-polyfill')).default;
	}
}
checkResizeObserver();

const root = createRoot(document.getElementById("root")!);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
