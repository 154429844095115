import { selectPermissions, useHMSStore } from "@100mslive/react-sdk";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CrossIcon,
  RecordIcon,
} from "@100mslive/react-icons";
import {
  Box,
  Flex,
  IconButton,
} from "../../mkui";
import {
  slideLeftAndFade,
  Switch,
  Text,
} from "@100mslive/roomkit-react";
import tw, {theme, css} from 'twin.macro';
import cn from "mxcn";

export const StreamCard = ({
  title,
  subtitle,
  Icon,
  imgSrc = "",
  css = {},
  onClick,
  testId,
}) => {
  return (
    <Flex
      className="w-full p-4 rounded-sm cursor-pointer bg-app-neutral-600 mb-4 mt-3"
      data-testid={testId}
      onClick={onClick}
    >
      <Text css={{ alignSelf: "center", p: "$4" }}>
        {imgSrc ? (
          <img src={imgSrc} height={40} width={40} alt="" />
        ) : (
          <Icon width={40} height={40} />
        )}
      </Text>
      <Box css={tw`flex-1 mx-3`}>
        <Text variant="h6" css={{ mb: "$4" }}>
          {title}
        </Text>
        <Text variant="sm" css={{ color: "$on_surface_medium" }}>
          {subtitle}
        </Text>
      </Box>
      <Text css={{ alignSelf: "center" }}>
        <ChevronRightIcon />
      </Text>
    </Flex>
  );
};

export const ContentHeader = ({ onBack, onClose, title = "", content }) => {
  return (
    <>
    <Flex direction="row" className="w-full py-3 px-4 items-stretch">
      {onBack ? (
        <div className="p-1 bg-app-secondary-400 self-center mr-3 rounded-full cursor-pointer flex-0"
          onClick={onBack}
          data-testid="go_back"
        >
          <ChevronLeftIcon width={16} height={16} />
        </div>
      ) : null}
      
      <Box className="flex-1 px-4 mt-1">
        {title ? (
          <p className="text-lg text-on-surface-medium font-semibold self-start">
            {title}
          </p>
        ) : null}
        <Text variant="h6">{content}</Text>
      </Box>

      {onClose ? (
        <IconButton
          size="xs"
          className="self-center ml-auto flex-0"
          onClick={onClose}
          data-testid="close_stream_section"
        >
          <CrossIcon width={16} height={16} />
        </IconButton>
      ) : null}    
    </Flex>
   </>
  );
};

export const Container = ({ children, rounded = false }) => {
  return (
    <Box className={cn("w-full h-full z-10 absolute top-0 left-0 flex flex-col overflow-y-auto overflow-x-hidden bg-app-neutral-800", rounded ? "" : "")}>
      {children}
    </Box>
  );
};

export const ContentBody = ({
  Icon,
  title,
  removeVerticalPadding = false,
  children,
}) => {
  return (
    <Box className={cn(removeVerticalPadding ? "py-0 px-4" : "P-4")}>
      <Text css={{ display: "flex", alignItems: "center", mb: "$4" }}>
        <Icon />
        <Text as="span" css={{ fontWeight: "$semiBold", ml: "$4" }}>
          {title}
        </Text>
      </Text>
      <Text variant="sm" css={{ color: "$on_surface_medium" }}>
        {children}
      </Text>
    </Box>
  );
};

export const RecordStream = ({ record, setRecord, testId }) => {
  const permissions = useHMSStore(selectPermissions);
  return permissions?.browserRecording ? (
    <Flex
      center
      className="bg-app-neutral-600 my-3 mx-4 p-4"
    >
      <Text css={{ color: "$alert_error_default" }}>
        <RecordIcon />
      </Text>
      <Text variant="sm" css={{ flex: "1 1 0", mx: "$8" }}>
        Record the stream
      </Text>
      <Switch
        checked={record}
        onCheckedChange={setRecord}
        data-testid={testId}
      />
    </Flex>
  ) : null;
};

export const ErrorText = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <Text variant="sm" css={{ mb: "$8", color: "$alert_error_default" }}>
      {error}
    </Text>
  );
};
