import { Arrow, Content, Popover as Root, Portal, Trigger } from '@radix-ui/react-popover';
import tw, {styled, theme} from 'twin.macro';


const StyledContent = styled(Content)(() =>[
	tw`z-20 popover-animation`,
	tw`dataSideTop:animate-slideDownAndFade`,
	tw`dataSideBottom:animate-slideUpAndFade`,
	tw`dataSideLeft:animate-slideRightAndFade`,
	tw`dataSideRight:animate-slideLeftAndFade`,
	{
		border: theme('components.popover.border'),
		borderRadius: theme('components.popover.borderRadius'),
		background: theme('components.popover.background'),
		color: theme('components.popover.color'),
		boxShadow: theme('components.popover.boxShadow'),
	}
]);


const StyledArrow = styled(Arrow)(() => [
	tw`popover-animation`,
	tw`dataSideTop:animate-slideDownAndFade`,
	tw`dataSideBottom:animate-slideUpAndFade`,
	tw`dataSideLeft:animate-slideRightAndFade`,
	tw`dataSideRight:animate-slideLeftAndFade`,
]);

const StyledTrigger = tw(Trigger)``;

export const Popover = {
  Root,
  Content: StyledContent,
  Trigger: StyledTrigger,
  Portal: Portal,
  Arrow: StyledArrow,
};
