import * as TabsPrimitive from '@radix-ui/react-tabs';
import tw from 'twin.macro'

const StyledTabsRoot = tw(TabsPrimitive.Root)`flex`

const StyledList = tw(TabsPrimitive.List)`flex flex-shrink-0`;

const StyledTrigger = tw(TabsPrimitive.Trigger)`
	font-sans text-base leading-4 select-none cursor-pointer flex items-center p-4
	bg-app-tab-bg text-app-tab-text
	dataActive:bg-app-tab-bgActive dataActive:text-app-tab-textActive
`;

const StyledContent = tw(TabsPrimitive.Content)`flex-1 p-0 outline-none`;

export const Tabs = {
  Root: StyledTabsRoot,
  List: StyledList,
  Content: StyledContent,
  Trigger: StyledTrigger,
};
