import { useCallback, useEffect } from "react";
import {
	selectIsConnectedToRoom,
	useCustomEvent,
	useHMSActions,
	useHMSStore,
	selectSessionMetadata,
	useAVToggle,
	selectLocalPeerRoleName,
} from "@100mslive/react-sdk";
// import {useSessionMetadata} from './useSetSessionMetadata';
import { useLocalStore } from "../../store/LocalStore";
import {Role} from "../../lib/peerTypes";

export const REFRESH_MESSAGE = "refresh";
export const METADATA_STATUS_MESSAGE_TYPE = "metadata_status";

export enum SessionMetadataType {
  muteAudio = "muteAudio",
  muteVideo = "muteVideo",
  muteScreenshare = "muteScreenshare",
  viewLayout = "viewLayout",
  allowConfetti = "allowConfetti",
  allowEmoji = "allowEmoji",
}

const muteAudioSlct = state => state.muteAudio;

/**
 * Refresh(re-populate) session metadata on receiving refresh broadcast message of type metadata
 */
export const useRefreshSessionMetadataStatus = () => {
	const hmsActions = useHMSActions();
	const isConnected = useHMSStore(selectIsConnectedToRoom);
	const curSessionMetadata = useHMSStore(selectSessionMetadata);
	const { isLocalAudioEnabled, toggleAudio } = useAVToggle();
	const localMuteAudio = useLocalStore(muteAudioSlct);
	const localPeerRole = useHMSStore(selectLocalPeerRoleName);
	
	// const { isLocalVideoEnabled, toggleVideo } = useAVToggle();

	useEffect(() => {
		if (isConnected) {
			hmsActions.populateSessionMetadata();
		}
	}, [hmsActions, isConnected]);

	useEffect(() => {
		// console.log(curSessionMetadata)
		if (localPeerRole === Role.Audience) {
			// adjust local settings for audio
			if (curSessionMetadata && toggleAudio) {
				if (curSessionMetadata[SessionMetadataType.muteAudio] === true) {
					if (isLocalAudioEnabled) {
						// console.log("Toggle session audio off.")
						toggleAudio()
					}
				} else if (curSessionMetadata[SessionMetadataType.muteAudio] === false) {
					if (!isLocalAudioEnabled && !localMuteAudio) {
						// console.log("Toggle session audio on.")
						toggleAudio()
					}
				}
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [curSessionMetadata, localMuteAudio, localPeerRole])

	const onEvent = useCallback(
		message => {
			if (message === REFRESH_MESSAGE) {
				hmsActions.populateSessionMetadata();
				// setTimeout(() => console.log(curSessionMetadata), 1000) // TODO: delete: only for testing
			}
		},
		[hmsActions]
	);

	useCustomEvent({
		type: METADATA_STATUS_MESSAGE_TYPE,
		json: false,
		onEvent,
	});
};
