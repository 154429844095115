import React, { ComponentProps } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import styled from '@emotion/styled';
import {theme} from 'twin.macro';
import { IconButton } from '../../../mkui';
import {CrossIcon} from '../../../styles/AppIcons';
import {dialogOpen, dialogClose} from '../../../mkui/utils/animations';


export const DialogClose = styled(DialogPrimitive.Close)``;

export const StyledDialogTrigger = styled(DialogPrimitive.Trigger)`{
	appearance: 'none !important', // Needed for safari it shows white overlay
}`

export const StyledDialogOverlay = styled(DialogPrimitive.Overlay)`{
	backgroundColor: 'rgba(0, 0, 0, 0.7);',
	position: 'fixed',
	inset: 0,
}`;

export const StyledDialogPortal = styled(DialogPrimitive.Portal)``;

const styledDialogContentStyles = {
	background: theme`components.dialog.background`,
	color: theme`colors.app.dialog.text`,
	borderRadius: theme`components.dialog.borderRadius`,
	border: theme`components.dialog.border`,
	boxShadow: theme`components.dialog.boxShadow`,
};

export const StyledDialogContent = styled(DialogPrimitive.Content)({
	...styledDialogContentStyles,
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: 999,
	padding: theme`spacing.6`,
	'@allowMotion': {
		'&[data-state="open"]': {
			animation: `${dialogOpen} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
		},
		'&[data-state="closed"]': {
			animation: `${dialogClose} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
		},
	},
	'&:focus': { outline: 'none' },
});

export const DialogTitle = styled(DialogPrimitive.Title)`{
	margin: 0,
}`;

export const DialogDescription = styled(DialogPrimitive.Description)``;

export const DialogDefaultCloseIcon = (props: ComponentProps<typeof IconButton>) => (
	<DialogClose asChild>
		<IconButton {...props} css={{}} size="xs" square noshadow>
			<CrossIcon />
		</IconButton>
	</DialogClose>
);
