import { useEffect } from "react";
import {
	HMSNotificationTypes,
	useHMSNotifications,
} from "@100mslive/react-sdk";
import { ToastBatcher } from "../Toast/ToastBatcher";
import {
	useIsHeadless,
	useSubscribedNotifications,
} from "../AppData/useUISettings";
import {
	useIsSidepaneTypeOpen,
} from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../lib/constants";
import {useDoUseChat } from "../AppData/useUISettings";
import { SUBSCRIBED_NOTIFICATIONS } from "../../lib/constants";


export const MessageNotifications = () => {
	const notification = useHMSNotifications(HMSNotificationTypes.NEW_MESSAGE);
	const isChatEnabled = useDoUseChat();
	const isChatSidePaneOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.CHAT);
	const isNewMessageSubscribed = useSubscribedNotifications(
		SUBSCRIBED_NOTIFICATIONS.NEW_MESSAGE
	);
	const isHeadless = useIsHeadless();

	useEffect(() => {
		if (!notification) {
			return;
		}
		// if (isChatSidePaneOpen) {
		// 	// only notify, when chat sidepane is closed
		// 	//notification.handled = true;
		// 	return
		// }
		// console.debug(`[${notification.type}]`, notification);
		if (
			!isNewMessageSubscribed ||
			notification.data?.ignored ||
			!isChatEnabled /*||
			notification.handled*/
		) {
			return;
		}
		ToastBatcher.showToast({ notification });
		//notification.handled = true;
	}, [notification, isNewMessageSubscribed, isHeadless, isChatEnabled]);

	return null;
};
