import * as LabelPrimitive from '@radix-ui/react-label';
import {styled, theme} from 'twin.macro'

// @ts-ignore
export const Label = styled(LabelPrimitive.Root)({
	color: theme`colors.app.label`,
  fontFamily: theme`components.label.fontFamily`,
  fontSize: theme`components.label.fontSize`,
  textTransform: theme`components.label.textTransform`,
  userSelect: 'none',
});

Label.displayName = "Label";
