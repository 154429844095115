import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import tw from "twin.macro";

const RadioGroupRoot = tw(RadioGroupPrimitive.Root)`
flex items-center`;

const RadioGroupItem = tw(RadioGroupPrimitive.Item)`
  relative bg-white w-4 h-4 -top-0.5 border-app-primary-default border-1 cursor-pointer
  rounded-full focus:shadow-none focus:outline-none
  data-stateChecked:border-2 data-stateChecked:p-1 data-stateChecked:bg-app-primary-400
`;

const RadioGroupIndicator = tw(RadioGroupPrimitive.Indicator)`
  bg-app-primary-default`;

export const RadioGroup = {
  Root: RadioGroupRoot,
  Item: RadioGroupItem,
  Indicator: RadioGroupIndicator,
};
