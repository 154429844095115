import * as React from 'react';
import { SVGProps } from 'react';

const SvgMicOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.244 2.244a.83.83 0 0 1 1.175 0l6.601 6.6c.026.022.051.044.075.068l4.268 4.268a.84.84 0 0 1 .066.074l2.143 2.142a.817.817 0 0 1 .311.312l4.873 4.873a.83.83 0 1 1-1.175 1.175l-4.473-4.472a6.676 6.676 0 0 1-3.27 1.37v1.677h2.5c.459 0 .834.375.834.833a.836.836 0 0 1-.834.834h-3.32a.635.635 0 0 1-.028 0H8.67a.83.83 0 0 1-.833-.834c0-.458.375-.833.833-.833h2.5v-1.672a6.663 6.663 0 0 1-4.734-2.929 6.748 6.748 0 0 1-1.1-3.734v-1.667c0-.459.375-.834.834-.834.458 0 .833.375.833.834v1.675c-.008 1 .284 1.976.834 2.8a5.075 5.075 0 0 0 2.258 1.86c.533.22 1.093.344 1.659.372a.828.828 0 0 1 .498-.001 4.986 4.986 0 0 0 2.67-.937l-1.226-1.227c-.32.188-.671.321-1.042.392-.216.05-.433.067-.65.067a3.23 3.23 0 0 1-1.275-.258 3.297 3.297 0 0 1-1.492-1.226 3.226 3.226 0 0 1-.567-1.842V9.845L2.244 3.42a.83.83 0 0 1 0-1.175ZM12.43 13.606l-2.085-2.085-.009-.009v.484c0 .333.1.65.284.925a1.7 1.7 0 0 0 .75.617 1.637 1.637 0 0 0 1.06.068Zm2.074-2.994a.836.836 0 0 1-.834-.833V5.328c0-.417-.15-.809-.433-1.117a1.657 1.657 0 0 0-1.067-.542 1.665 1.665 0 0 0-1.8 1.325.835.835 0 0 1-.984.65.827.827 0 0 1-.65-.975 3.342 3.342 0 0 1 5.751-1.567c.542.609.85 1.4.85 2.234v4.451a.829.829 0 0 1-.833.825Zm4.167-.283a.836.836 0 0 0-.833-.834.836.836 0 0 0-.834.834v1.667c0 .3-.025.592-.083.883a.837.837 0 0 0 .675.967.838.838 0 0 0 .967-.675 6.23 6.23 0 0 0 .108-1.175v-1.667Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMicOffIcon;
