import * as React from 'react';
import { SVGProps } from 'react';

const SvgCopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.708 4.167c0-.806.653-1.459 1.459-1.459H15c.805 0 1.458.653 1.458 1.459v7.5c0 .805-.653 1.458-1.458 1.458h-1.667v-1.25H15a.208.208 0 0 0 .208-.208v-7.5A.208.208 0 0 0 15 3.958H9.167a.208.208 0 0 0-.209.209v1.666h-1.25V4.167ZM5 6.875c-.805 0-1.458.653-1.458 1.458v7.5c0 .806.653 1.459 1.458 1.459h5.833c.806 0 1.459-.653 1.459-1.459v-7.5c0-.805-.653-1.458-1.459-1.458H5Zm-.208 1.458c0-.115.093-.208.208-.208h5.833c.115 0 .209.093.209.208v7.5a.208.208 0 0 1-.209.209H5a.208.208 0 0 1-.208-.209v-7.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCopyIcon;
