import React, { ComponentProps } from 'react';
import * as ToastPrimitives from '@radix-ui/react-toast';
import tw, {styled, theme} from 'twin.macro';
// import cn from 'mxcn';
import { CrossIcon } from '../styles/AppIcons';
import { Flex, IconButton } from './';
import { toastAnimation } from './utils/animations';

type TToastVariant = "info" | "error" | "warning" | "neutral" | "standard"
type ToastColorMap = Record<TToastVariant, string>;

const bgColorMap:ToastColorMap = {
	info: theme('components.toast.background'),
	standard: theme('components.toast.background'),
	error: theme('colors.app.warning.500'),
	warning: theme('colors.app.warning.400'),
	neutral: theme('colors.app.neutral.400'),
}

const hiliteColorMap:ToastColorMap = {
	info: theme('components.toast.hilite'),
	standard: theme('components.toast.hilite'),
	error: theme('colors.app.warning.500'),
	warning: theme('colors.app.warning.400'),
	neutral: theme('colors.app.neutral.400'),
}

const fgColorMap:ToastColorMap = {
	info: theme('components.toast.color'),
	standard: theme('components.toast.color'),
	error: theme('colors.app.textNeg'),
	warning: theme('colors.app.textNeg'),
	neutral: theme('colors.app.textNeg'),
}

interface IToastVariant {
	toastVariant?: TToastVariant;
}
// type ToastPropTypes = JSX.IntrinsicElements["li"] & IToastVariant;


const getToastVariant = (toastVariant: TToastVariant) => {
	return {
		borderLeftColor: bgColorMap[toastVariant],
		borderLeft: 0,
		'&:before': {
			position: 'absolute',
			top: '-1px',
			left: '-4px',
			width: '8px',
			borderLeft: `solid 2px ${hiliteColorMap[toastVariant]}`,
			// borderTop: `solid 2px ${bgColorMap[toastVariant]}`,
			// borderBottom: `solid 2px ${bgColorMap[toastVariant]}`,
			borderTopLeftRadius: theme('components.toast.borderRadius'),
			borderBottomLeftRadius: theme('components.toast.borderRadius'),
			background: bgColorMap[toastVariant],
			content: '" "',
			height: '100%',
			zIndex: 10,
		},
		["@media (min-width:" + theme`screens.sm` + ")"]: {
			"&:before": {
				content: 'none',
			},
			borderLeft: `solid 2px ${hiliteColorMap[toastVariant]}`
		}
	};
};

// @ts-ignore
const ToastRoot = styled(ToastPrimitives.Root)(({toastVariant = "info"}:IToastVariant) => [
	{
		borderRadius: theme('components.toast.borderRadius'),
		background: bgColorMap[toastVariant],
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		borderLeft: theme('components.toast.hilite'),
		overflow: 'hidden',
		...toastAnimation,
	},
	tw`font-sans`,
	tw`p-3`,
	{
		["@media (min-width:" + theme('screens.sm') + ")"]: {
			padding: "8px 10px",
		},
	},
	{
		borderLeftColor: hiliteColorMap[toastVariant],
		borderLeft: 0,
		'&:before': {
			position: 'absolute',
			top: '-1px',
			left: '-4px',
			width: '8px',
			borderLeft: `solid 2px ${hiliteColorMap[toastVariant]}`,
			borderTopLeftRadius: theme('components.toast.borderRadius'),
			borderBottomLeftRadius: theme('components.toast.borderRadius'),
			background: bgColorMap[toastVariant],
			content: '" "',
			height: '100%',
			zIndex: 10,
		},
		["@media (min-width:" + theme`screens.sm` + ")"]: {
			"&:before": {
				content: 'none',
			},
			borderLeft: `solid 2px ${hiliteColorMap[toastVariant]}`
		}
	}
]);

// const ToastRoot = ({toastVariant = "info"}:TToastVariant) => <ToastRootSt toastVariant={toastVariant} />

const ToastProvider = tw(ToastPrimitives.Provider)`w-[24rem] max-w-[85vw]`

const ToastTitle = tw(ToastPrimitives.Title)`
	text-app-color1 flex items-center justify-between
`;

const ToastDescription = tw(ToastPrimitives.Description)`
text-app-text
`;

const ToastClose = tw(ToastPrimitives.Close)``;

// const ToastAction = tw(ToastPrimitives.Action)`
// 	cursor-pointer
// `;

const ToastAction = tw.div`
	cursor-pointer
`;

const ToastViewport = tw(ToastPrimitives.Viewport)`
	fixed bottom-0 left-0 flex flex-col p-1 md:p-2 gap-2 w-full md:w-72
	max-w-[100vw] m-0 list-none z-500
`;

const DefaultClose = ({ className }: Pick<ComponentProps<typeof ToastClose>, 'className'>) => {
	return (
		<ToastClose className={className + " ml-3 self-start"} asChild>
			<IconButton size="xs" noshadow className='!bg-transparent'>
				<CrossIcon />
			</IconButton>
		</ToastClose>
	);
};

interface HMSToastProps extends ToastPrimitives.ToastProps {
	title: string;
	description?: string;
	isClosable?: boolean;
	icon?: React.ReactNode;
	action?: React.ReactNode;
	inlineAction?: boolean;
	toastVariant?: TToastVariant;
}

const HMSToast: React.FC<HMSToastProps> = ({
	title,
	description,
	isClosable = true,
	icon,
	action,
	inlineAction = false,
	toastVariant = "info",
}) => {
	return (
		// <ToastRoot toastVariant={toastVariant}> // TODO: make variants work
		<ToastRoot>
			<ToastTitle>
				<Flex align="start" direction='row' className='gap-1 flex-1 min-w-0'>
					{icon && <div className="w-6 h-6 self-start mt-1">{icon}</div>}
					<div className="font-sans font-normal break-words">
						{title}
					</div>
				</Flex>
				{isClosable ? <DefaultClose /> : null}
				{!isClosable && inlineAction && action ? (
					// <ToastAction altText={`${title} Action`}>{action}</ToastAction>
					<ToastAction>{action}</ToastAction>
				) : null}
			</ToastTitle>
			{description ? (
				<ToastDescription>
					<div className='text-app-neutral-300'>
						{description}
					</div>
				</ToastDescription>
			) : null}
			{!inlineAction && action ? (
				// <ToastAction altText={`${title} Action`} className='mt-2'>
				<ToastAction className='mt-2'>
					{action}
				</ToastAction>
			) : null}
		</ToastRoot>
	);
};
export const Toast = {
	Provider: ToastProvider,
	Root: ToastRoot,
	Title: ToastTitle,
	Description: ToastDescription,
	Close: DefaultClose,
	Action: ToastAction,
	Viewport: ToastViewport,
	HMSToast: HMSToast,
};
