import * as React from 'react';
import { SVGProps } from 'react';

const SvgBrbIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1ZM2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm3 10V8h1.765c.388 0 .715.056.984.169.276.112.502.262.675.449.174.187.296.404.368.652.079.24.118.49.118.753v.292c0 .217-.02.4-.06.55-.03.15-.082.281-.153.393-.134.21-.34.39-.616.54.284.127.493.314.628.562.134.247.201.584.201 1.01v.45c0 .704-.182 1.244-.545 1.618-.355.375-.928.562-1.718.562H5Zm1.209-3.708v2.562h.52c.246 0 .435-.034.57-.101a.715.715 0 0 0 .32-.281c.07-.12.114-.262.13-.427.016-.165.024-.345.024-.54 0-.202-.012-.378-.036-.528a.931.931 0 0 0-.142-.382.645.645 0 0 0-.32-.224 1.495 1.495 0 0 0-.533-.079h-.533Zm0-3.213v2.202h.545c.402 0 .671-.094.805-.281.143-.195.214-.476.214-.843 0-.36-.08-.629-.237-.809-.15-.18-.427-.27-.83-.27H6.21Zm5.045 0v2.494h.687c.213 0 .383-.026.51-.079a.722.722 0 0 0 .295-.247c.064-.105.107-.232.13-.382.024-.157.036-.337.036-.54 0-.201-.012-.377-.035-.527a1.037 1.037 0 0 0-.142-.405c-.15-.21-.435-.314-.853-.314h-.628ZM10.045 16V8h1.943c1.422 0 2.133.783 2.133 2.348 0 .472-.08.873-.237 1.203-.15.33-.419.595-.806.797L14.382 16h-1.28l-1.126-3.416h-.723V16h-1.208Zm5.045-8v8h1.647c.79 0 1.363-.187 1.718-.562.363-.374.545-.914.545-1.618v-.45c0-.426-.067-.763-.201-1.01a1.284 1.284 0 0 0-.628-.562c.276-.15.481-.33.616-.54.07-.112.122-.243.154-.393.04-.15.059-.333.059-.55v-.292c0-.263-.04-.514-.119-.753a1.591 1.591 0 0 0-.367-.652 1.859 1.859 0 0 0-.675-.45C17.57 8.057 17.242 8 16.855 8H15.09Zm1.209 6.854v-2.562h.533c.22 0 .399.026.533.079.134.045.24.12.32.224a.931.931 0 0 1 .142.382c.024.15.035.326.035.529 0 .194-.007.374-.023.539-.016.165-.06.307-.13.427a.715.715 0 0 1-.32.28c-.135.068-.324.102-.57.102h-.52Zm0-3.573V9.079h.497c.403 0 .68.09.83.27.157.18.236.449.236.808 0 .367-.07.648-.213.843-.134.187-.403.28-.806.28H16.3Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBrbIcon;
