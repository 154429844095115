import React, { useEffect } from "react";
import {
	selectLocalPeerID,
	selectLocalPeerName,
	selectLocalPeerRoleName,
	selectSessionId,
	selectRoomID,
	useHMSStore,
} from "@100mslive/react-sdk";
import { setUpLogRocket } from "./initUtils";
import { FeatureFlagsInit } from "../../services/FeatureFlags";

const Init = () => {
	const localPeerID = useHMSStore(selectLocalPeerID);
	const localPeerRole = useHMSStore(selectLocalPeerRoleName);
	const localPeerName = useHMSStore(selectLocalPeerName);
	const sessionId = useHMSStore(selectSessionId);
	const roomId = useHMSStore(selectRoomID);


	useEffect(() => {
		function setWindowSize() {
			// make size of window accessible for layouts
			// document.body.style.height = `${window.innerHeight}px`;
			// document.body.style.width = `${window.innerWidth}px`;
			document.body.setAttribute('data-height', "" + window.innerHeight);
			document.body.setAttribute('data-width', "" + window.innerWidth);
		}
		// reset the height whenever the window's resized
		window.addEventListener("resize", setWindowSize);
		// called to initially set the height.
		setWindowSize();
		return () => {
			window.removeEventListener("resize", setWindowSize);
		};
	}, []);

	useEffect(() => {
		if (localPeerID && localPeerRole && localPeerName) {
			setUpLogRocket({
				localPeer: {
					id: localPeerID,
					name: localPeerName,
					roleName: localPeerRole,
				},
				roomId,
				sessionId,
			});
		}
		// eslint-disable-next-line
	}, [localPeerID, localPeerName, localPeerRole, roomId, sessionId]);

	return <FeatureFlagsInit />;
};

export { Init };
