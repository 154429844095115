import { Fragment, useState } from "react";
import { useMedia } from "react-use";
import {
	selectAppData,
	selectIsConnectedToRoom,
	selectPermissions,
	useHMSActions,
	useHMSStore,
	useRecordingStreaming,
} from "@100mslive/react-sdk";
import { EndStreamIcon, RecordIcon } from "../../styles/AppIcons";
import {
	Box,
	Button,
	Flex,
	Loading,
	Popover,
	Tooltip,
} from "../../mkui";
import GoLiveButton from "../GoLiveButton";
import { ResolutionInput } from "../Streaming/ResolutionInput";
import { getResolution } from "../Streaming/RTMPStreaming";
import { ToastManager } from "../Toast/ToastManager";
import { AdditionalRoomState, getRecordingText } from "./AdditionalRoomState";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { useSetAppDataByKey } from "../AppData/useUISettings";
import { getDefaultMeetingUrl } from "../../lib/utils";
import {
	APP_DATA,
	RTMP_RECORD_DEFAULT_RESOLUTION,
	SIDE_PANE_OPTIONS,
} from "../../lib/constants";
import { UI_SETTINGS } from "../../lib/constants";
import { useUISettings } from "../AppData/useUISettings";


export const LiveStatus = () => {
	const { isHLSRunning, isRTMPRunning } = useRecordingStreaming();
	if (!isHLSRunning && !isRTMPRunning) {
		return null;
	}
	return (
		<Flex align="center">
			<Box className="w-2 h-2 rounded bg-app-primary-default mr-2" />
			<span>
				Live
				<span as="span" className="hidden md:inline">
					&nbsp;über {isHLSRunning ? "HLS" : "RTMP"}
				</span>
			</span>
		</Flex>
	);
};

export const RecordingStatus = () => {
	const {
		isBrowserRecordingOn,
		isServerRecordingOn,
		isHLSRecordingOn,
		isRecordingOn,
	} = useRecordingStreaming();
	const permissions = useHMSStore(selectPermissions);

	if (
		!isRecordingOn ||
		// if only browser recording is enabled, stop recording is shown
		// so no need to show this as it duplicates
		[
			permissions?.browserRecording,
			!isServerRecordingOn,
			!isHLSRecordingOn,
			isBrowserRecordingOn,
		].every(value => !!value)
	) {
		return null;
	}
	return (
		<Tooltip
			title={getRecordingText({
				isBrowserRecordingOn,
				isServerRecordingOn,
				isHLSRecordingOn,
			})}
		>
			<Box className="text-app-color1">
				<RecordIcon width={24} height={24} />
			</Box>
		</Tooltip>
	);
};

const EndStream = () => {
	const { isStreamingOn } = useRecordingStreaming();
	const toggleStreaming = useSidepaneToggle(SIDE_PANE_OPTIONS.STREAMING);
	if (!isStreamingOn) {
		return null;
	}
	return (
		<Button
			data-testid="end_stream"
			variant="standard"
			outline
			icon
			onClick={() => {
				toggleStreaming();
			}}
		>
			<EndStreamIcon />
			Stream Beenden
		</Button>
	);
};

const StartRecording = () => {
	const permissions = useHMSStore(selectPermissions);
	const recordingUrl = useHMSStore(selectAppData(APP_DATA.recordingUrl));
	const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);
	const [open, setOpen] = useState(false);
	const [recordingStarted, setRecordingState] = useSetAppDataByKey(
		APP_DATA.recordingStarted
	);
	const { isBrowserRecordingOn, isStreamingOn, isHLSRunning } =
		useRecordingStreaming();
	const hmsActions = useHMSActions();
	if (!permissions?.browserRecording || isHLSRunning) {
		return null;
	}
	if (isBrowserRecordingOn) {
		return (
			<Popover.Root open={open} onOpenChange={setOpen}>
				<Popover.Trigger asChild>
					<Button
						variant="danger"
						data-testid="stop_recording"
						icon
						outline
						onClick={() => setOpen(true)}
					>
						<RecordIcon />
						<span className="hidden md:inline">
							Aufnahme stoppen
						</span>
					</Button>
				</Popover.Trigger>
				<Popover.Portal>
					<Popover.Content align="end" sideOffset={8} className="w-52">
						<div className="text-lg text-app-dialog-text">
							Bist Du sicher, dass Du die Aufnahme beenden möchtest?
						</div>
						<Button
							data-testid="stop_recording_confirm"
							variant="danger"
							icon
							className="ml-auto"
							onClick={async () => {
								try {
									await hmsActions.stopRTMPAndRecording();
								} catch (error) {
									ToastManager.addToast({
										title: error.message,
										variant: "error",
									});
								}
								setOpen(false);
							}}
						>
							Beenden
						</Button>
					</Popover.Content>
				</Popover.Portal>
			</Popover.Root>
		);
	}
	return (
		<Popover.Root open={open} onOpenChange={setOpen}>
			<Popover.Trigger asChild>
				<Button
					data-testid="start_recording"
					variant="standard"
					icon
					size="xs"
					disabled={recordingStarted || isStreamingOn}
					onClick={() => setOpen(true)}
				>
					{recordingStarted ? (
						<Loading size={24} color="currentColor" />
					) : (
						<RecordIcon className="mr-2" />
					)}
					<span className="hidden md:inline">
						Aufnahme {recordingStarted ? "startet" : "starten"}
					</span>
				</Button>
			</Popover.Trigger>
			<Popover.Content align="end" sideOffset={8} className="w-52">
				<ResolutionInput
					testId="recording_resolution"
					css={{ flexDirection: "column", alignItems: "start" }}
					onResolutionChange={setResolution}
				/>
				<Button
					data-testid="start_recording_confirm"
					variant="primary"
					icon
					className="ml-auto"
					type="submit"
					disabled={recordingStarted || isStreamingOn}
					onClick={async () => {
						try {
							setRecordingState(true);
							await hmsActions.startRTMPOrRecording({
								meetingURL: recordingUrl || getDefaultMeetingUrl(),
								resolution: getResolution(resolution),
								record: true,
							});
						} catch (error) {
							if (error.message.includes("stream already running")) {
								ToastManager.addToast({
									title: "Die Aufnahme läuft bereits",
									variant: "error",
								});
							} else {
								ToastManager.addToast({
									title: error.message,
									variant: "error",
								});
							}
							setRecordingState(false);
						}
						setOpen(false);
					}}
				>
					Start
				</Button>
			</Popover.Content>
		</Popover.Root>
	);
};

export const StreamActions = () => {
	const isConnected = useHMSStore(selectIsConnectedToRoom);
	const permissions = useHMSStore(selectPermissions);
	const isMobile = useMedia('(max-width: 480px)');
	const allowRecording = useUISettings(UI_SETTINGS.allowRecording);
	const allowStreaming = useUISettings(UI_SETTINGS.allowStreaming);
	const { isStreamingOn } = useRecordingStreaming();

	return (
		<Flex align="center" direction="row" className="gap-28">
			<AdditionalRoomState />
			{/* {allowRecording &&  */}
			<Flex align="center" direction="row" className="gap-2 hidden md:flex">
				<LiveStatus />
				<RecordingStatus />
			</Flex>
			{/* } */}
			{/* {isConnected && allowRecording && !isMobile ? <StartRecording /> : null}
			{isConnected && allowStreaming && (permissions.hlsStreaming || permissions.rtmpStreaming) && (
				<Fragment>
					<GoLiveButton />
					<EndStream />
				</Fragment>
			)} */}
			{isConnected && !isMobile ? <StartRecording /> : null}
      {isConnected &&
        (permissions.hlsStreaming || permissions.rtmpStreaming) && (
          <Fragment>
            {isStreamingOn ? <EndStream /> : <GoLiveButton />}
          </Fragment>
        )}
		</Flex>
	);
};
