// import React, {useState} from 'react';
// import {
// 	Navigate,
// } from "react-router-dom";
import {useTranslation} from "react-i18next";
// import cn from 'mxcn';
import {Flex} from '../mkui';


const HomeScreen = () => {
	const {t} = useTranslation();
	// const [btnHover, setBtnHover] = useState(false);

/* 	const handleEnter = (evt) => {
		if (evt.type === "mouseenter") {
			setBtnHover(true);
		} else {
			setBtnHover(false);
		}
	} */

	return (
		<>
		{/* <Navigate to='./login' /> */}

		<Flex center className="w-full h-full overflow-y-scroll">
			<div className='text-center mt-16'>
				<div className="headline-font text-sm uppercase mb-2">{t("Willkommen im")}</div>
				<h1 className='text-6xl mb-16'>Treff.Burg</h1>
			</div>
			<div className='flex justify-center flex-grow mb-12 z-20 max-w-2xl'>
				<p className='text-xl'>Hier geht es zu den Veranstaltungen der Treff.Burg. Wenn Du Zugangsdaten hast, klicke unten auf „zum Login“.</p>
				{/* <div className='flex flex-row gap-x-6'>
					<IconButton size="small" text="Mikrofon">
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton size="small">
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton size="small" square>
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton size="small" square text="Mikrofon">
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
				</div>
				<div className='flex flex-row gap-x-6'>
					<IconButton color="primary" text="Mikrofon">
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton selected>
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton square>
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton color="tertiary" square text="Mikrofon">
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
				</div>
				<div className='flex flex-row gap-x-6'>
					<IconButton color="primary" size="large" text="Mikrofon">
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton color="secondary" size="large">
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton color="secondary" size="large" square>
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
					<IconButton color="warning" size="large" square text="Mikrofon" selected={true}>
						<MicOffIcon data-testid="audio_off_btn" />
					</IconButton>
				</div> */}
			</div>
			<div className='flex flex-row justify-center relative'>
				<img src="/img/Homepage_Couch_bg.png" alt="" className='absolute bottom-0 max-w-4xl' />
				{/* className={cn(btnHover ? 'opacity-100' : 'opacity-50', 'duration-300 transition-opacity absolute bottom-0 max-w-none')} */}
				<div className='relative z-10 max-w-[460px]'>
					<a href="login" className='group app-btn-text-only p-0 m-0'>
						<img src="/img/Homepage_Couch_btn.png" alt="" className='relative' />
						<div className='absolute left-1/2 bottom-36 -translate-x-1/2 btn-text primary group-hover:bg-app-primary-300 duration-300 transition-colors'>Zum Login
						</div>
					</a>
					<img src="/img/Homepage_elements_left.png" alt="" className='absolute z-20 -left-1/2 bottom-0 pointer-events-none' />
					<img src="/img/Homepage_elements_right.png" alt="" className='absolute z-20 -right-32 bottom-0' />
				</div>
			</div>
		</Flex>
		</>
	)
};

export default HomeScreen;
