import * as React from 'react';
import { SVGProps } from 'react';

const SvgHangUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.879 8.601a.75.75 0 0 1 .072.012 4.704 4.704 0 0 1 3.684 4.593v.782a2.068 2.068 0 0 1-2.068 2.067h-3.295a2.068 2.068 0 0 1-2.068-2.067.568.568 0 0 0-.568-.568H9.364a.568.568 0 0 0-.568.568 2.068 2.068 0 0 1-2.068 2.067H3.433a2.068 2.068 0 0 1-2.068-2.067v-.782M18.879 8.6a36.435 36.435 0 0 0-13.83.01 4.704 4.704 0 0 0-3.684 4.595m3.856-3.863v.043l.065.291-.065-.334Zm.142.735a3.204 3.204 0 0 0-2.498 3.127v.783a.568.568 0 0 0 .568.568h3.295a.568.568 0 0 0 .568-.568 2.068 2.068 0 0 1 2.068-2.068h5.272a2.068 2.068 0 0 1 2.068 2.068.568.568 0 0 0 .568.568h3.295a.568.568 0 0 0 .568-.568v-.782a3.204 3.204 0 0 0-2.499-3.124c-4.383-.85-8.889-.85-13.273-.003v-.002Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHangUpIcon;
