/* eslint-disable no-case-declarations */
import React, { useEffect } from "react";
import LogRocket from "logrocket";
import {
	HMSNotificationTypes,
  selectLocalPeerID,
  useHMSNotifications,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import { ToastBatcher } from "../Toast/ToastBatcher";
import { ToastManager } from "../Toast/ToastManager";
import { AutoplayBlockedModal } from "./AutoplayBlockedModal";
import { InitErrorModal } from "./InitErrorModal";
import { MessageNotifications } from "./MessageNotifications";
import { PeerNotifications } from "./PeerNotifications";
import { PermissionErrorModal } from "./PermissionErrorModal";
import { ReconnectNotifications } from "./ReconnectNotifications";
import { TrackBulkUnmuteModal } from "./TrackBulkUnmuteModal";
import { TrackNotifications } from "./TrackNotifications";
import { TrackUnmuteModal } from "./TrackUnmuteModal";
import { useWidgetToggle } from "../AppData/useSidepane";
import {
	useHLSViewerRole,
	useIsHeadless,
	useSubscribedNotifications,
} from "../AppData/useUISettings";
import { useNavigation } from "../hooks/useNavigation";
import { getMetadata } from "../../lib/utils";
import { roleNamesMap } from "../../lib/peerTypes";

export function Notifications() {
	const localPeerID = useHMSStore(selectLocalPeerID);
	const notification = useHMSNotifications();
	const navigate = useNavigation();
	const HLS_VIEWER_ROLE = useHLSViewerRole();
	const subscribedNotifications = useSubscribedNotifications() || {};
	const isHeadless = useIsHeadless();
	const toggleWidget = useWidgetToggle();
	
	useEffect(() => {
		if (!notification) {
			return;
		}
		switch (notification.type) {
			case HMSNotificationTypes.METADATA_UPDATED:
				// Don't toast message when metadata is updated and raiseHand is false.
				// Don't toast message in case of local peer.
				const metadata = getMetadata(notification.data?.metadata);
				if (!metadata?.isHandRaised || notification.data.isLocal || isHeadless)
					return;

				console.debug("Metadata updated", notification.data);
				if (!subscribedNotifications.METADATA_UPDATED) return;
				ToastBatcher.showToast({ notification });
				break;

			case HMSNotificationTypes.NAME_UPDATED:
				console.log(
					notification.data.id +
						" hat den Namen geändert in " +
						notification.data.name
				);
				break;

			case HMSNotificationTypes.ERROR:
				if (
					notification.data?.isTerminal &&
					notification.data?.action !== "INIT"
				) {
					if ([500, 6008].includes(notification.data?.code)) {
						ToastManager.addToast({
							title: `Error: ${notification.data?.message}`,
						});
					} else {
						LogRocket.track("Disconnected");
						// show button action when the error is terminal
						const toastId = ToastManager.addToast({
							title: (
								<Flex justify="between" css={{ w: "100%" }}>
									<Text css={{ mr: "$4" }}>
										{notification.data?.message ||
											"Die Verbindung konnte nicht hergestellt werden. Bitte überprüfe deine Internetverbindung."}
									</Text>
								´</Flex>),
							inlineAction: true,
              action: (
									<Button
										variant="primary"
										css={{ mr: "$4" }}
										onClick={() => {
											ToastManager.removeToast(toastId);
											window.location.reload();
										}}
									>
										Wieder eintreten
									</Button>
							),
							close: false,
						});
					}
					// goto leave for terminal if any action is not performed within 2secs
					// if network is still unavailable going to preview will throw an error
					setTimeout(() => {
						// const previewLocation = window.location.pathname.replace(
						//   "event",
						//   "leave"
						// );
						ToastManager.clearAllToast();
						// navigate(previewLocation);
					}, 2000);
					return;
				}
				// Autoplay error or user denied screen share(cancelled browser pop-up)
				if (
					notification.data?.code === 3008 ||
					notification.data?.code === 3001 ||
					notification.data?.code === 3011
				) {
					return;
				}
				if (notification.data?.action === "INIT") {
					return;
				}
				if (!subscribedNotifications.ERROR) return;
				ToastManager.addToast({
					title: `Error: ${notification.data?.message} - ${notification.data?.description}`,
				});
				break;

			case HMSNotificationTypes.ROLE_UPDATED:
				if (notification.data.roleName === HLS_VIEWER_ROLE) {
					return;
				}
				if (notification.data?.isLocal) {
					ToastManager.addToast({
						title: `Du hast nun die Funktion ${roleNamesMap[notification.data.roleName]}`,
					});
				}
				break;

			case HMSNotificationTypes.CHANGE_TRACK_STATE_REQUEST:
				const track = notification.data?.track;
				if (!notification.data.enabled) {
					ToastManager.addToast({
						title: `Dein ${track.source} ${track.type} wurde von
								${notification.data.requestedBy?.name} gemuted.`,
					});
				}
				break;

			case HMSNotificationTypes.REMOVED_FROM_ROOM:
			case HMSNotificationTypes.ROOM_ENDED:
				ToastManager.addToast({
					title: `${notification.message}. 
							${
								notification.data.reason &&
								`Grund: ${notification.data.reason}`
							}`,
				});
				setTimeout(() => {
					const leaveLocation = window.location.pathname.replace(
						"event",
						"leave"
					);
					navigate(leaveLocation);
					ToastManager.clearAllToast();
				}, 2000);
				break;

			case HMSNotificationTypes.DEVICE_CHANGE_UPDATE:
				ToastManager.addToast({
					title: notification.message,
				});
				break;
			case HMSNotificationTypes.POLL_STARTED:
				if (notification.data.startedBy !== localPeerID) {
					ToastManager.addToast({
						title: `Eine Umfrage wurde gestartet: ${notification.data.title}`,
						action: (
							<Button
								onClick={() => toggleWidget(notification.data.id)}
								variant="standard"
								css={{
									backgroundColor: "$surface_bright",
									fontWeight: "$semiBold",
									color: "$on_surface_high",
									p: "$xs $md",
								}}
							>
								Abstimmen
							</Button>
						),
					});
				}
				break;
				
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		notification,
		subscribedNotifications.ERROR,
		subscribedNotifications.METADATA_UPDATED,
		HLS_VIEWER_ROLE,
	]);

	return (
		<>
			{!isHeadless && <TrackUnmuteModal />}
			{!isHeadless && <TrackBulkUnmuteModal />}
			{/* <TrackNotifications /> */}
			<PeerNotifications />
			<ReconnectNotifications />
			<AutoplayBlockedModal />
			<PermissionErrorModal />
			<MessageNotifications />
			<InitErrorModal notification={notification} />
		</>
	);
}
