import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import translationEN from './en.json';
import translationDE from './de.json';

const resources = {
	de: {
		translation: translationDE,
	},
	en: {
		translation: translationEN,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "de",
		interpolation: {
			escapeValue: false,
		},
		keySeparator: false,
		resources,
	})
// console.log(i18n)
if (i18n.language !== "de" && i18n.language !== "en") {
	if (i18n.language.slice(0, 2) === "en") {
		localStorage.setItem("i18nextLng", "en")
		i18n.changeLanguage("en")
	} else {
		localStorage.setItem("i18nextLng", "de")
		i18n.changeLanguage("de")
	}
};

export default i18n;
