/** @jsxImportSource @emotion/react */
import React, { forwardRef, useRef, useState } from "react";
import {
	selectPeerByID,
	useHMSActions,
	useHMSStore,
} from "@100mslive/react-sdk";
import {
	CheckIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "../styles/AppIcons";
import {
	Checkbox,
} from "@100mslive/roomkit-react";
import {Button, Flex, Label, Dropdown, Tooltip} from "../mkui";
// import {textEllipsis} from "../styles/styles";
import cn from 'mxcn';
import {Dialog} from "./Dialogs/Dialogs";
import { roleNamesMap } from "../lib/peerTypes";
import { changeableRoles } from "../lib/utils";

const PeerName = forwardRef(({ children, maxWidth, ...rest }, ref) => (
	<span
		{...rest}
		ref={ref}
		className={cn("inline-block text-inherit font-semibold" /*, textEllipsis(maxWidth)*/)}
		>
		{children}
	</span>
));

export const RoleChangeModal = ({ peerId, onOpenChange }) => {
	const peer = useHMSStore(selectPeerByID(peerId));
	const [selectedRole, setRole] = useState(peer?.roleName);
	const [requestPermission, setRequestPermission] = useState(true);
	const hmsActions = useHMSActions();
	const [open, setOpen] = useState(false);
	const [peerNameRef, setPeerNameRef] = useState();
	const triggerRef = useRef();
	if (!peer) {
		return null;
	}
	const peerNameMaxWidth = 200;

	return (
		<Dialog.Root defaultOpen onOpenChange={onOpenChange}>
			<Dialog.Portal>
				<Dialog.Overlay />
				<Dialog.Content className="p-4 w-[min(420px,80%)]">
					<Dialog.Title className="p-0" asChild>
						<h5>
							Rolle wechseln
						</h5>
					</Dialog.Title>
					<Dialog.Description asChild>
						<div className="flex gap-x-2 flex-wrap mt-2 mb-4 text-app-textNeg">
							Wechsle die Rolle von
							{peerNameRef && peerNameRef.clientWidth === peerNameMaxWidth ? (
								<Tooltip title={peer.name} side="top">
									<PeerName ref={setPeerNameRef} maxWidth={peerNameMaxWidth}>
										{peer.name}
									</PeerName>
								</Tooltip>
							) : (
								<PeerName ref={setPeerNameRef} maxWidth={peerNameMaxWidth}>
									{peer.name}
								</PeerName>
							)}
							zu
						</div>
					</Dialog.Description>
					<Flex align="center" className="w-full mb-5 text-app-neutral-contrast">
						<div className="relative flex-1 min-w-0 min-w-[200px]">
							<Dropdown.Root
								open={open}
								onOpenChange={setOpen}
								className="w-full"
							>
								<Dropdown.Trigger
									data-testid="open_role_selection_dropdown"
									asChild
									className="border-dropdown bg-app-surfaceHilite rounded-dropdown py-1.5 px-4"
									ref={triggerRef}
								>
									<Flex
										align="center"
										direction="row"
										justify="between"
										className="w-full"
									>
										<span>{roleNamesMap[selectedRole]}</span>
										{open ? <ChevronUpIcon /> : <ChevronDownIcon />}
									</Flex>
								</Dropdown.Trigger>
								{/* <Dropdown.Portal> */}
									<Dropdown.Content
										align="start"
										sideOffset={8}
										className={cn(triggerRef.current && `w-[${triggerRef.current.clientWidth}px]`, "max-h-[--radix-popper-available-height] overflow-y-scroll")}
										collisionPadding={{top: 40, left: 0}}
									>
										{changeableRoles.map(role => {
											return (
												<Dropdown.Item
													data-testid={role}
													key={role}
													onSelect={() => setRole(role)}
													className={cn("px-4", role === selectedRole ? 'bg-app-secondary-100' : null)}
												>
													{roleNamesMap[role]}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Content>
								{/* </Dropdown.Portal> */}
							</Dropdown.Root>
						</div>
					</Flex>
					{!peer.isLocal && (
						<Flex justify="start" direction="row" className="w-full mb-6">
							<Checkbox.Root
								checked={requestPermission}
								onCheckedChange={value => setRequestPermission(value)}
								id="requestRoleChangePermission"
								data-testid="force_role_change_checkbox"
								className="mr-2"
							>
								<Checkbox.Indicator>
									<CheckIcon width={16} height={16} />
								</Checkbox.Indicator>
							</Checkbox.Root>
							<Label
								htmlFor="requestRoleChangePermission"
								className="cursor-pointer !text-app-neutral-contrast"
							>
								Erlaubnis anfragen
							</Label>
						</Flex>
					)}
					<Flex
						justify="center"
						direction="row"
						align="center"
						gap="4"
						className="w-full"
					>
						<div className="w-1/2">
							<Dialog.Close className="w-full" asChild>
								<Button
									variant="standard"
									size="small"
									className="w-full"
									data-testid="cancel_button"
								>
									Abbrechen
								</Button>
							</Dialog.Close>
						</div>
						<div className="w-1/2">
							<Button
								variant="primary"
								size="small"
								className="w-full"
								data-testid="change_button"
								onClick={async () => {
									await hmsActions.changeRoleOfPeer(
										peerId,
										selectedRole,
										peer.isLocal ? true : !requestPermission
									);
									onOpenChange(false);
								}}
							>
								Wechseln
							</Button>
						</div>
					</Flex>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
