import { QUERY_PARAM_SKIP_PREVIEW, QUESTION_TYPE } from "./constants";
import {Role} from './peerTypes';

type HexColor = `#${string}`;

export function shadeColor(color:HexColor, percent:number) {
	let R = parseInt(color.substring(1, 3), 16);
	let G = parseInt(color.substring(3, 5), 16);
	let B = parseInt(color.substring(5, 7), 16);

	R = Math.floor((R * (100 + percent)) / 100);
	G = Math.floor((G * (100 + percent)) / 100);
	B = Math.floor((B * (100 + percent)) / 100);

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	const RR =
		R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
	const GG =
		G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
	const BB =
		B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

	return "#" + RR + GG + BB;
}

/**
 * TODO: this is currently an O(N**2) function, don't use with peer lists, it's currently
 * being used to find intersection between list of role names where the complexity shouldn't matter much.
 */
export const arrayIntersection = (a:Array<any>, b:Array<any>) => {
	if (a === undefined || b === undefined) {
		return [];
	}
	// ensure "a" is the bigger array
	if (b.length > a.length) {
		let t = b;
		b = a;
		a = t;
	}
	return a.filter(function (e) {
		return b.indexOf(e) > -1;
	});
};

export const getMetadata = (metadataString:string) => {
	try {
		return metadataString === "" ? {} : JSON.parse(metadataString);
	} catch (error) {
		return {};
	}
};

export const metadataProps = function (peer):Record<string, any> {
	return {
		isHandRaised: getMetadata(peer.metadata)?.isHandRaised,
		roleSpecifier: getMetadata(peer.metadata)?.roleSpecifier,
	};
};

export const getPeerOriginalRole = (peer):Role => {
	return getMetadata(peer.metadata)?.originalRole || peer.role;
};

export const isScreenshareSupported = ():boolean => {
	return typeof navigator.mediaDevices.getDisplayMedia !== "undefined";
};

export const getDefaultMeetingUrl = () => {
	return (
		window.location.href.replace("meeting", "preview") +
		`?${QUERY_PARAM_SKIP_PREVIEW}=true`
	);
};

export const getRoutePrefix = () => {
	return window.location.pathname.startsWith("/streaming") ? "/streaming" : "";
};

export const isStreamingKit = () => {
	return window.location.pathname.startsWith("/streaming");
};

export const isInternalRole = (role:Role):boolean => {
	const internalRoles = [Role.MK_Monitor, Role.Watchonly];
	if (role) {
		if (internalRoles.indexOf(role) !== -1) {
			return true;
		}
	}
	return false;
}

export const metadataPayloadParser = payload => {
  try {
    const data = window.atob(payload);
    const parsedData = JSON.parse(data);
    return parsedData;
  } catch (e) {
    return { payload };
  }
};

export const isValidURL = url => {
  const urlPattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
  return !!urlPattern.test(url);
};

const compareArrays = (a, b) => {
  if (a.length !== b.length) return false;
  else {
    // Comparing each element of your array
    for (var i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
};

export const checkCorrectAnswer = (answer, localPeerResponse, type) => {
  if (type === QUESTION_TYPE.SINGLE_CHOICE) {
    return answer?.option === localPeerResponse?.option;
  } else if (type === QUESTION_TYPE.MULTIPLE_CHOICE) {
    return (
      answer?.options &&
      localPeerResponse?.options &&
      compareArrays(answer?.options, localPeerResponse?.options)
    );
  }
};

export const isValidTextInput = (text, minLength = 2, maxLength = 100) => {
  return text && text.length >= minLength && text.length <= maxLength;
};

// Roles which are to be presented in “exposed places” (e.g. larger)
export const exposedRoles = [Role.Presenter, Role.Actor, Role.Audience, Role.Moderator, Role.Videoplayer];
export const isExposedRole = (role:Role):boolean => exposedRoles.indexOf(role) !== -1;

// Roles with special priviledges like changing other peers role or quit other peers
export const privilegedRoles = [Role.Backstage, Role.Actor, Role.Director, Role.Moderator, Role.MK_Monitor];
export const isPriviledgedRole = (role:Role):boolean => privilegedRoles.indexOf(role) !== -1;

export const changeableRoles = [Role.Presenter, Role.Audience, Role.Moderator, Role.Backstage, Role.Actor, Role.Director];

export const randomInt = (min:number, max:number):number=> (
	Math.floor(Math.random() * (max - min + 1) + min)
);

export function intToHex(num:number) {
	return num.toString(16);
}

export const shuffleArray = (array:[]):[] => {
  const shuffled:[] = [...array]; // create a new array with the same elements
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}