
export enum Role {
	Actor = "actor",
	Presenter = "presenter",
	Backstage = "backstage",
	Audience = "audience",
	Director = "director",
	Commander = "commander",
	Moderator = "moderator",
	Audioplayer = "audioplayer",
	Videoplayer = "videoplayer",
	Viewer = "viewer", // meeting peer, but invisible and can not use interactive tools
	Watchonly = "watchonly", // receiving complete stream but can use chat
	// Passivewatch = "passivewatch", // receiving complete stream without any interactive tools
	PinnedOverlay = "pinnedoverlay", // for sign translation
	MK_Monitor = "mk_monitor",
	Subtitler = "subtitler",
	PinnedAudience = "pinnedaudience", // always on position 1 of audience panes
}

export enum RoleSpecifier {
	Actor1 = "Act1",
	Actor2 = "Act2",
	Actor3 = "Act3",
	Actor4 = "Act4",
	Actor5 = "Act5",
	Actor6 = "Act6",
	Video1 = "Vid1",
	Video2 = "Vid2",
	PinnedOverlay1 = "pinnedOverlay1",
	PinnedOverlay2 = "pinnedOverlay2",
	PinnedOverlay3 = "pinnedOverlay3",
	PinnedOverlay4 = "pinnedOverlay4",
}

export const roleNamesMap:Record<Role, string> = {
	[Role.Actor]: "Schauspieler*in",
	[Role.Presenter]: "Präsentation",
	[Role.Backstage]: "Backstage",
	[Role.Audience]: "Publikum",
	[Role.Director]: "Regie",
	[Role.Commander]: "Commander",
	[Role.Moderator]: "Moderation",
	[Role.Audioplayer]: "Audioplayer",
	[Role.Videoplayer]: "Videoplayer",
	[Role.Viewer]: "Zuschauer (passiv)",
	[Role.MK_Monitor]: "MK Monitor",
	[Role.Watchonly]: "Watchonly",
	[Role.PinnedOverlay]: "Pinned Overlay",
	[Role.Subtitler]: "Subtitler",
	[Role.PinnedAudience]: "Pinned",
}