import * as React from 'react';
import { SVGProps } from 'react';

const SvgShieldAlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.076 3.052c.186-.07.39-.07.575 0l6.546 2.455c.319.12.53.425.53.766V12c0 2.82-1.867 5.073-3.552 6.548a18.568 18.568 0 0 1-3.42 2.353l-.017.009-.005.002-.002.001-.367-.731-.367.732-.002-.002-.005-.002-.018-.01a6.77 6.77 0 0 1-.286-.152 18.563 18.563 0 0 1-3.134-2.2C6.867 17.073 5 14.82 5 12V6.273c0-.341.212-.647.53-.766l6.546-2.455Zm-.079 17.861c.23.116.502.116.733 0l-.366-.731-.367.732Zm.367-1.662a16.924 16.924 0 0 0 2.734-1.935C16.685 15.927 18.09 14.09 18.09 12V6.84l-5.727-2.148L6.636 6.84V12c0 2.09 1.406 3.927 2.994 5.316a16.922 16.922 0 0 0 2.734 1.936Zm0-11.342c.451 0 .818.366.818.818V12a.818.818 0 0 1-1.636 0V8.727c0-.452.366-.818.818-.818Zm0 6.546a.818.818 0 1 0 0 1.636h.008a.818.818 0 0 0 0-1.636h-.008Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgShieldAlertIcon;
