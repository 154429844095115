import React, { forwardRef } from 'react';
import cn from 'mxcn';


const flexClasses = {
	base: 'flex',
	justify: {
		start: 'justify-start',
		end: 'justify-end',
		center: 'justify-center',
		between: 'justify-between',
		around: 'justify-around',
		evenly: 'justify-evenly',
	},
	align: {
		start: 'items-start',
		end: 'items-end',
		center: 'items-center',
		baseline: 'items-baseline',
		stretch: 'items-stretch',
	},
	direction: {
		row: 'flex-row',
		column: 'flex-col',
		col: 'flex-col',
		rowReverse: 'flex-row-reverse',
		columnReverse: 'flex-col-reverse',
	},
	center: 'justify-center items-center',
	gap: {
		1: 'gap-1',
		2: 'gap-2',
		4: 'gap-4',
		6: 'gap-6',
		8: 'gap-8',
		10: 'gap-10',
	},
};

interface FlexProps {
	justify?: string;
	align?: string;
	direction?: "column" | "col" | "row" | "columnReverse" | "rowReverse";
	center?: boolean;
	gap?: string | number;
	fillSpace?: boolean;
	css?: any;
};

type PropTypes = JSX.IntrinsicElements["div"] & FlexProps;


export const Flex = forwardRef<HTMLDivElement, PropTypes>(
	({
		children,
		className,
		justify,
		align,
		direction = "column",
		gap,
		fillSpace,
		center,
		...rest
	}, ref) => (
		<div
			ref={ref}
			className={
				cn(
					flexClasses.base,
					(justify && !center) && flexClasses.justify[justify],
					(align && !center) && flexClasses.align[align],
					flexClasses.direction[direction],
					gap && flexClasses.gap[gap],
					center && flexClasses.center,
					fillSpace && 'w-full h-full',
					className
				)
			}
			{...rest}
		>
			{children}
		</div>
	)
);

export const Flex2 = ({justify, align, direction = "column", center, fillSpace, gap}:PropTypes) => (
	<div className={cn(
			flexClasses.base,
			(justify && !center) && flexClasses.justify[justify],
			(align && !center) && flexClasses.align[align],
			flexClasses.direction[direction],
			gap && flexClasses.gap[gap],
			center && flexClasses.center,
			fillSpace && 'w-full h-full',
		)
	} />
);
