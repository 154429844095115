import React, { useEffect, useState } from "react";
import { selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk";
import cn from 'mxcn';
// import { Toast as ToastPrimitive } from "@100mslive/roomkit-react";
import { Toast as ToastPrimitive } from "../../mkui";
import { Toast } from "./Toast";
import { ToastManager } from "./ToastManager";
import { MAX_TOASTS } from "../../lib/constants";

export const ToastContainer = () => {
	const isConnected = useHMSStore(selectIsConnectedToRoom);
	const [toasts, setToast] = useState([]);

	useEffect(() => {
		ToastManager.addListener(setToast);
		
		return () => {
			ToastManager.removeListener(setToast);
		};
	}, []);
	
	return (
		<ToastPrimitive.Provider swipeDirection="left" duration={3000}>
			{toasts.slice(0, MAX_TOASTS).map(toast => {
				return (
					<Toast
						key={toast.id}
						{...toast}
						onOpenChange={value => !value && ToastManager.removeToast(toast.id)}
					/>
				);
			})}
			{/* <ToastPrimitive.Viewport css={!isConnected ? {} : { bottom: "$24" }} /> */}
			<ToastPrimitive.Viewport className={cn(isConnected && "bottom-6")} />
		</ToastPrimitive.Provider>
	);
};
