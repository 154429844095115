import tw from 'twin.macro';
// import { Flex } from './';

const Root = tw.div`flex justify-between items-center py-2 relative h-full gap-2`;

const Left = tw.div`flex items-center absolute left-4 gap-1`;

const Center = tw.div`flex w-full justify-center items-center gap-1`;

const Right = tw.div`items-center absolute right-4 gap-1 hidden md:flex`;

export const Footer = {
  Root,
  Left,
  Center,
  Right,
};
