import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDebounce, useMeasure } from "react-use";
import { FixedSizeList } from "react-window";
import {
	selectAudioTrackByPeerID,
	selectLocalPeerID,
	// selectPeerCount,
	selectPeerMetadata,
	selectLocalPeerRoleName,
	selectPermissions,
	useHMSActions,
	useHMSStore,
	useParticipants,
} from "@100mslive/react-sdk";
import {
	ChangeRoleIcon,
	CrossIcon,
	HandRaiseIcon,
	PeopleIcon,
  RemoveUserIcon,
	SearchIcon,
	SpeakerIcon,
	VerticalMenuIcon,
} from "../../styles/AppIcons";
import {
	Avatar,
	Box,
	Dropdown,
	Flex,
  Input,
	IconButton,
	Slider,
  // textEllipsis,
} from "../../mkui";
import { ConnectionIndicator } from "../Connection/ConnectionIndicator";
import { RoleChangeModal } from "../RoleChangeModal";
import { ParticipantFilter } from "./ParticipantFilter";
import {
	useIsSidepaneTypeOpen,
	useSidepaneToggle,
} from "../AppData/useSidepane";
import { useOriginalRole } from "../../lib/hooks";
import { isInternalRole, privilegedRoles } from "../../lib/utils";
import { SIDE_PANE_OPTIONS } from "../../lib/constants";
import {Role, roleNamesMap} from "../../lib/peerTypes"

let hiddenRoles = [Role.Commander, Role.Director, Role.Viewer, Role.MK_Monitor];


export const ParticipantList = () => {
	const [filter, setFilter] = useState();
	const { participants, isConnected, peerCount, rolesWithParticipants } =
		useParticipants(filter);
	const [selectedPeerId, setSelectedPeerId] = useState(null);
	const localPeerRole = useHMSStore(selectLocalPeerRoleName);
	const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
	const onSearch = useCallback(value => {
		setFilter(filterValue => {
			if (!filterValue) {
				filterValue = {};
			}
			filterValue.search = value;
			return { ...filterValue };
		});
	}, []);
	
	useEffect(() => {
		if (localPeerRole === Role.MK_Monitor) {
			hiddenRoles = []
		}
	}, [localPeerRole])
	
	// filter the "no show" participants
	const filteredParticipants = participants.filter(pt => hiddenRoles.indexOf(pt.roleName) === -1);

	if (peerCount === 0) {
		return null;
	}

	return (
		<Fragment>
			<Flex direction="column" className="w-full h-full">
				<Flex direction="row" align="top" className="w-full mb-4">
					<div className="font-bold text-lg text-app-textNeg">Teilnehmer</div>
					<ParticipantFilter
						selection={filter}
						onSelection={setFilter}
						isConnected={isConnected}
						roles={rolesWithParticipants}
						className="min-w-32 ml-6"
					/>
					<IconButton
						onClick={toggleSidepane}
						className="w-8 h-8 ml-auto p-0 -mt-2"
						name=""
						noshadow
					>
						<CrossIcon />
					</IconButton>
				</Flex>
				
				{!filter?.search && filteredParticipants.length === 0 ? null : (
					<ParticipantSearch onSearch={onSearch} />
				)}
				{filteredParticipants.length === 0 && (
					<Flex align="center" justify="center" className="w-full py-4 text-app-textNeg">
						<div>
							{!filter ? "Keine Teilnehmer" : "Keine Teilnehmer gefunden"}
						</div>
					</Flex>
				)}
				<VirtualizedParticipants
					participants={filteredParticipants}
					isConnected={isConnected}
					setSelectedPeerId={setSelectedPeerId}
				/>
			</Flex>
			{selectedPeerId && (
				<RoleChangeModal
					peerId={selectedPeerId}
					onOpenChange={value => {
						!value && setSelectedPeerId(null);
					}}
				/>
			)}
		</Fragment>
	);
};

export const ParticipantCount = () => {
	// const peerCount = useHMSStore(selectPeerCount);
	const {participants} = useParticipants();
	const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
	const localPeerRole = useHMSStore(selectLocalPeerRoleName);
	const isParticipantsOpen = useIsSidepaneTypeOpen(
		SIDE_PANE_OPTIONS.PARTICIPANTS
	);

	useEffect(() => {
		if (localPeerRole === Role.MK_Monitor) {
			hiddenRoles = []
		}
	}, [localPeerRole])

	// filter the "no show" participants
	const filteredParticipants = participants.filter(pt => hiddenRoles.indexOf(pt.roleName) === -1);
	const peerCount = filteredParticipants.length;
	
	useEffect(() => {
		if (isParticipantsOpen && peerCount === 0) {
			toggleSidepane();
		}
	}, [isParticipantsOpen, peerCount, toggleSidepane]);

	if (peerCount === 0) {
		return (
			<span>0</span>
		);
	}

	return (
		<IconButton
			className="w-auto p-2 h-auto"
			onClick={() => {
				if (peerCount > 0) {
					toggleSidepane();
				}
			}}
			active={!isParticipantsOpen}
			data-testid="participant_list"
		>
			<PeopleIcon />
			<span className="mx-2 text-app-textNeg text-sm">
				{peerCount}
			</span>
		</IconButton>
	);
};

function itemKey(index, data) {
	return data.participants[index].id;
}

const VirtualizedParticipants = ({
	participants,
	isConnected,
	setSelectedPeerId,
}) => {
	const [ref, { width, height }] = useMeasure();
	return (
		<Box
			ref={ref}
			className="flex-1 -mr-4"
		>
			<FixedSizeList
				itemSize={68}
				itemData={{ participants, isConnected, setSelectedPeerId }}
				itemKey={itemKey}
				itemCount={participants.length}
				width={width}
				height={height}
			>
				{VirtualisedParticipantListItem}
			</FixedSizeList>
		</Box>
	);
};

const VirtualisedParticipantListItem = React.memo(({ style, index, data }) => {
	return (
		<div style={style} key={data.participants[index].id}>
			<Participant
				peer={data.participants[index]}
				isConnected={data.isConnected}
				setSelectedPeerId={data.setSelectedPeerId}
			/>
		</div>
	);
});

const Participant = ({ peer, isConnected, setSelectedPeerId }) => {
	return (
		<Flex
			key={peer.id}
			direction="row"
			className="w-full py-2 pr-4"
			align="center"
			data-testid={"participant_" + peer.name}
		>
			<Avatar
				name={peer.name}
				className="mr-4 w-6 h-6 p-2 text-sm"
			/>
			<Flex direction="column" className="flex-1">
				<span variant="md" className="font-bold">
					{peer.name}
				</span>
				<span className="text-sm">{roleNamesMap[peer.roleName]}</span>
			</Flex>
			{isConnected && (
				<ParticipantActions
					peerId={peer.id}
					role={peer.roleName}
					onSettings={() => {
						setSelectedPeerId(peer.id);
					}}
				/>
			)}
		</Flex>
	);
};

/**
 * shows settings to change for a participant like changing their role
 */
const ParticipantActions = React.memo(({ onSettings, peerId, role }) => {
	const originalRole = useOriginalRole();
	const isHandRaised = useHMSStore(selectPeerMetadata(peerId))?.isHandRaised;
	const canChangeRole = (privilegedRoles.indexOf(originalRole) > -1); //useHMSStore(selectPermissions)?.changeRole;
	const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));
	const localPeerId = useHMSStore(selectLocalPeerID);
	const canChangeVolume = peerId !== localPeerId && audioTrack;
	const shouldShowMoreActions = canChangeRole || canChangeVolume;

	return (
		<Flex align="center" className="flex-shrink-0">
			<ConnectionIndicator peerId={peerId} />
			{isHandRaised && <HandRaiseIcon />}
			{shouldShowMoreActions && !isInternalRole(role) && (
				<ParticipantMoreActions
					onRoleChange={onSettings}
					peerId={peerId}
					role={role}
				/>
			)}
		</Flex>
	);
});

const ParticipantMoreActions = ({ onRoleChange, peerId }) => {
	const originalRole = useOriginalRole();
	// const { changeRole: canChangeRole, removeOthers: canRemoveOthers } =
  //   useHMSStore(selectPermissions);
	const { removeOthers: canRemoveOthers } = useHMSStore(selectPermissions);
	const canChangeRole = (privilegedRoles.indexOf(originalRole) > -1);
	const localPeerId = useHMSStore(selectLocalPeerID);
  const isLocal = localPeerId === peerId;
  const actions = useHMSActions();
	const [open, setOpen] = useState(false);

	// const realyCanChangeRole = canChangeRole && (privilegedRoles.indexOf(originalRole) > -1);

	return (
		<Dropdown.Root open={open} onOpenChange={value => setOpen(value)}>
			<Dropdown.Trigger
				asChild
				data-testid="participant_more_actions"
				className="p-1"
				tabIndex={0}
			>
				<div>
					<VerticalMenuIcon />
				</div>
			</Dropdown.Trigger>
			<Dropdown.Portal>
				<Dropdown.Content align="end" sideOffset={8} className="w-48">
					{canChangeRole && (
						<Dropdown.Item onClick={() => onRoleChange(peerId)}>
							<ChangeRoleIcon />
							<div className="ml-2">Rolle ändern</div>
						</Dropdown.Item>
					)}
					<ParticipantVolume peerId={peerId} />
          {(!isLocal && canRemoveOthers) && (
            <Dropdown.Item
              onClick={async () => {
                try {
                  await actions.removePeer(peerId, "");
                } catch (error) {
                  // TODO: Toast here
                }
              }}
            >
              <RemoveUserIcon />
              <div className="ml-2 text-app-warning-700">
                Teilnehmer beenden
              </div>
            </Dropdown.Item>
          )}
				</Dropdown.Content>
			</Dropdown.Portal>
		</Dropdown.Root>
	);
};

const ParticipantVolume = ({ peerId }) => {
	const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));
	const localPeerId = useHMSStore(selectLocalPeerID);
	const hmsActions = useHMSActions();
	// No volume control for local peer or non audio publishing role
	if (peerId === localPeerId || !audioTrack) {
		return null;
	}

	return (
		<Dropdown.Item className="h-auto w-48">
			<Flex direction="column" className="w-full">
				<Flex align="center" direction="row">
					<SpeakerIcon />
					<span className="ml-2">
						Lautstärke{audioTrack.volume ? `(${audioTrack.volume})` : ""}
					</span>
				</Flex>
				<Slider
					className="my-2"
					step={5}
					value={[audioTrack.volume]}
					onValueChange={e => {
						hmsActions.setVolume(e[0], audioTrack?.id);
					}}
				/>
			</Flex>
		</Dropdown.Item>
	);
};

export const ParticipantSearch = ({ onSearch, placeholder }) => {
	const [value, setValue] = React.useState("");
	useDebounce(
		() => {
			onSearch(value);
		},
		300,
		[value, onSearch]
	);
	return (
		<Box className="relative my-4 py-2 px-0">
			<Box className="absolute left-2 top-1 transform translate-y-1/2 text-app-primary-400">
				<SearchIcon />
			</Box>
			<Input
				type="text"
				placeholder={placeholder || "Suchbegriff eingeben"}
				className="w-full !pl-8"
				value={value}
				onKeyDown={event => {
					event.stopPropagation();
				}}
				onChange={event => {
					setValue(event.currentTarget.value);
				}}
				autoComplete="off"
				aria-autocomplete="none"
			/>
		</Box>
	);
};
