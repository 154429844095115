import { selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk";
import { BrbIcon, HandIcon } from "../styles/AppIcons";
import { Flex, Tooltip, IconButton } from "../mkui";
import { useMyMetadata } from "./hooks/useMetadata";

const MetaActions = ({ isMobile = false, compact = false }) => {
	const isConnected = useHMSStore(selectIsConnectedToRoom);
	const { isHandRaised, isBRBOn, toggleHandRaise, toggleBRB } = useMyMetadata();

	if (!isConnected) {
		return null;
	}

	return (
		<Flex align="center" direction="row" className="gap-2">
			<Tooltip title={`${!isHandRaised ? "Melden" : "Nicht melden"}`}>
				<IconButton
					onClick={toggleHandRaise}
					active={isHandRaised}
					data-testid={`${
						isMobile ? "raise_hand_btn_mobile" : "raise_hand_btn"
					}`}
					size="small"
				>
					<HandIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title={`${isBRBOn ? `Bin wieder da` : `Bin gleich zurück`}`}>
				<IconButton onClick={toggleBRB} active={isBRBOn} data-testid="brb_btn" size="small">
					<BrbIcon />
				</IconButton>
			</Tooltip>
		</Flex>
	);
};

export default MetaActions;
