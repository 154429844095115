import React from 'react';
import * as BaseSlider from '@radix-ui/react-slider';
import { Tooltip } from './Tooltip';
import tw from 'twin.macro';

const Root = tw(BaseSlider.Root)`
	relative flex items-center justify-center select-none touch-none w-full dataOrientHorizontal:h-1
`;

const Track = tw(BaseSlider.Track)`
	relative flex-grow rounded-full bg-app-neutral-500 cursor-pointer dataOrientHorizontal:h-1
`;

const Range = tw(BaseSlider.Range)`
	absolute bg-app-primary-default rounded-full h-full
`;

const Thumb = tw(BaseSlider.Thumb)`
	block w-6 h-6 bg-app-primary-default cursor-pointer shadow-button rounded-full hover:bg-app-primary-300
`;

type SliderProps = React.ComponentProps<typeof Root> & {
	thumbStyles?: string;
	showTooltip?: boolean;
	css?: any;
};

export const Slider: React.FC<SliderProps & { showTooltip?: boolean }> = ({
	showTooltip = true,
	thumbStyles,
	...props
}) => (
	<Root {...props}>
		<Track>
			<Range />
		</Track>
		{showTooltip ? (
			<Tooltip title={String(props.value?.[0])}>
				<Thumb className={thumbStyles} />
			</Tooltip>
		) : (
			<Thumb className={thumbStyles} />
		)}
	</Root>
);
