import React, { useEffect, useState } from "react";
import { ErrorDialog } from "../Dialogs/Dialogs";

export const InitErrorModal = ({ notification }) => {
	const [showModal, setShowModal] = useState(false);
	const [info, setInfo] = useState({ title: "Init Error", description: "" });

	useEffect(() => {
		const data = notification?.data;
		if (!data || data.action !== "INIT") {
			return;
		}
		let description;
		let title;
		if (data.description.includes("role is invalid")) {
			description =
				"This role does not exist for the given room. Try again with a valid role.";
			title = "Invalid Role";
		} else if (data.description.includes("room is not active")) {
			title = "Der Raum ist nicht aktiv";
			description =
				"Dieser Raum kann nicht betreten werden. Bitte wende dich an den Veranstalter.";
		} else {
			description = data.description;
			title = "Init Error";
		}
		setInfo({ title, description });
		setShowModal(true);
	}, [notification]);

	return (
		<ErrorDialog
			open={showModal}
			onOpenChange={setShowModal}
			title={info.title}
		>
			<div className="text-sm">
				{info.description} <br />
				Aktuelle URL - {window.location.href}
			</div>
		</ErrorDialog>
	);
};
