// @ts-check
import { useCallback } from "react"
import {
	// selectPeerNameByID,
	selectSessionMetadata,
	useHMSActions,
	useHMSStore,
	// useHMSVanillaStore,
	// HMSRoleName,
} from "@100mslive/react-sdk"
import {
	METADATA_STATUS_MESSAGE_TYPE,
	REFRESH_MESSAGE,
	SessionMetadataType
} from "./useRefreshSessionMetadataStatus"


export interface SetSessionMetadataParams {
	mdType: SessionMetadataType;
	value: any;
}

/**
 * set pinned chat message by updating the session metadata
 * and broadcasting metadata refresh message to other peers
 */
export const useSetSessionMetadata = () => {
	const hmsActions = useHMSActions();
	// const vanillaStore = useHMSVanillaStore();
	let curSessionMetadata = useHMSStore(selectSessionMetadata);
	if (!curSessionMetadata) curSessionMetadata = {}

	const setSessionMetadata = useCallback(
		async ({mdType, value}:SetSessionMetadataParams) => {
			//const msg:IMuteMessage = {
			//	type,
			//	doMute
			//}
			// console.log("setSessionMetadata:", mdType, value)
			const smd:any = {}
			switch(mdType) {
				case SessionMetadataType.muteAudio:
					smd[SessionMetadataType.muteAudio] = value
					break;
				case SessionMetadataType.viewLayout:
					smd[SessionMetadataType.viewLayout] = value
					break;
				default:
					
			}

			const newSessionMetadata = {...curSessionMetadata, ...smd} // Object.assign(oldSessionMetaData, muteData)
			
			await hmsActions.setSessionMetadata(newSessionMetadata);
			await hmsActions.sendBroadcastMessage(
				REFRESH_MESSAGE,
				METADATA_STATUS_MESSAGE_TYPE
			);
		},
		[curSessionMetadata, hmsActions]
	);

	return { setSessionMetadata };
};

export const useSessionMetadata = (smdst:SessionMetadataType, defaultValue?:any | undefined):any => {
	const curSessionMetadata = useHMSStore(selectSessionMetadata);
	const value = curSessionMetadata ? (curSessionMetadata[smdst] !== undefined ? curSessionMetadata[smdst] : defaultValue) : defaultValue;
	return value;
}

