import {RoleSpecifier, Role} from "./peerTypes";


export enum ViewLayout {
	GridBasic = "GridBasic",

	/**
	 * Stage play layouts
	 */
	// square actors (S), round audience (R)
	// c = center, t = top, b = bottom, l = left, r = right
	ActDScAsp__Aud1Rt_Aud2Rb = "ActDScAsp__Aud1Rt_Aud2Rb",
	ActDScAsp__noAud = "ActDScAsp__noAud",
	ActDScFit__noAud = "ActDScFit__noAud",

	Act1Sc__Aud1Rt_Aud2Rb = "Act1Sc__Aud1Rt_Aud2Rb",
	Act2Sc__Aud1Rt_Aud2Rb = "Act2Sc__Aud1Rt_Aud2Rb",
	Act3Sc__Aud1Rt_Aud2Rb = "Act3Sc__Aud1Rt_Aud2Rb",
	Vid1Sc__Aud1Rt_Aud2Rb = "Vid1Sc__Aud1Rt_Aud2Rb",

	ActDSrFit__Aud1Rl = "ActDSrFit__Aud1Rl", // Actors dynamic right fit, Audience left
	ActDSrAsp__Aud1Rl = "ActDSrAsp__Aud1Rl", // Actors dynamic right asp, Audience left
	Act1SrAsp__Aud1Rl = "Act1SrAsp__Aud1Rl", // Actor 1 right, Audience left
	Act2SrAsp__Aud1Rl = "Act2SrAsp__Aud1Rl", // Actor 2 right, Audience left
	Act3SrAsp__Aud1Rl = "Act3SrAsp__Aud1Rl", // Actor 3 right, Audience left
	Vid1SrAsp__Aud1Rl = "Vid1SrAsp__Aud1Rl", // Videoplayer 1 right, Audience left

	// Act1Sc_Act2Sc__Aud1Rt_Aud2Rb = "Act1Sc_Act2Sc__Aud1Rt_Aud2Rb",
	// Act2Sc_Act3Sc__Aud1Rt_Aud2Rb = "Act2Sc_Act3Sc__Aud1Rt_Aud2Rb",
	// Act1Sc_Act3Sc__Aud1Rt_Aud2Rb = "Act1Sc_Act3Sc__Aud1Rt_Aud2Rb",
	// Vid1Sc_Act1Sc__Aud1Rt_Aud2Rb = "Vid1Sc_Act1Sc__Aud1Rt_Aud2Rb",
	// Vid1Sc_Act2Sc__Aud1Rt_Aud2Rb = "Vid1Sc_Act2Sc__Aud1Rt_Aud2Rb",
	// Vid1Sc_Act3Sc__Aud1Rt_Aud2Rb = "Vid1Sc_Act3Sc__Aud1Rt_Aud2Rb",
	Act1ScAsp__noAud = "Act1ScAsp__noAud",
	Act1ScFit__noAud = "Act1ScFit__noAud",
	Vid1ScAsp__noAud = "Vid1ScAsp__noAud",
	Vid1ScFit__noAud = "Vid1ScFit__noAud",
	// TestLayout = "TestLayout",

	/**
	 * Workshop Layouts
	 */
	// Workshop _ Exposed(Position) __ Audience(Position)
	Wshp_ExpCenter__AudSides = "Wshp_ExpCenter__AudSides",
	Wshp_ExpCenter__AudTopBottom = "Wshp_ExpCenter__AudTopBottom",
	Wshp_GridCircles = "Wshp_GridCircles",
	Wshp_Vid1Sc__Aud1Rt_Aud2Rb = "Wshp_Vid1Sc__Aud1Rt_Aud2Rb",
	Wshp_Vid1ScFit__noAud = "Wshp_Vid1ScFit__noAud",

}

export const defaultLayout = ViewLayout.ActDScAsp__Aud1Rt_Aud2Rb;

/*
 * We stick with "StageView" most of the time and
 * use a layout within this view.
 */
export enum EventView {
	StageView = "StageView", // for stage plays
	WSGridView = "WSGridView",
	HLSView = "HLSView",
	EmbedView = "EmbedView",
	WhiteboardView = "WhiteboardView",
	ActiveSpeakerView = "ActiveSpeakerView",
}

type TDirection = "row" | "column";
type TPosition = "center" | "top" | "bottom" | "left" | "right" | "top_bottom" | "left_right";
type TShape= "rect" | "round" | "roundedrect";


interface IActiveActor {
	roleSpecifier?: RoleSpecifier;
	roles?: Role[];
	size: "aspect" | "cover" | "contain" | "dynamic"; // dynamic may be cover for 1 item, aspect 4:3 for 2 items etc.
	shape: TShape;
}

interface IAudienceArea {
	position: TPosition,
	shape: TShape,
	maxPanes: number, // may be number of rows or columns
	maxItemsPerPane: number, // number or tiles per row or column
	maxHeight: number,
	maxWidth: number,
	aspect: number,
}

export interface IViewLayoutSpecs {
	actors: {
		direction: TDirection;
		position: TPosition;
		type: "static" | "dynamic";
		active: IActiveActor[],
	},
	audience?: {
		direction: TDirection;
		areas: IAudienceArea[];
	}
}

export const ViewLayoutSpecs:Record<string, IViewLayoutSpecs> = {
	ActDScAsp__Aud1Rt_Aud2Rb: {
		actors: {
			direction: "row",
			position: "center",
			type: "dynamic",
			active: [
				{
					roles: [Role.Actor, Role.Videoplayer],
					size: "aspect",
					shape: "rect",
				},
			]
		},
		audience: {
			direction: "row",
			areas: [
				{
					position: "top_bottom",
					shape: "round",
					maxPanes: 3,
					maxItemsPerPane: 7,
					maxHeight: 136,
					maxWidth: 136,
					aspect: 1,
				},
				// {
				// 	position: "bottom",
				// 	shape: "round",
				// 	maxPanes: 1,
				// 	maxItemsPerPane: 7,
				// 	maxHeight: 136,
				// 	maxWidth: 136,
				// 	aspect: 1,
				// },
			]
		}
	},
	
	ActDScAsp__noAud: {
		actors: {
			direction: "row",
			position: "center",
			type: "dynamic",
			active: [
				{
					roles: [Role.Actor, Role.Videoplayer],
					size: "aspect",
					shape: "rect",
				},
			]
		},
	},

	ActDScFit__noAud: {
		actors: {
			direction: "row",
			position: "center",
			type: "dynamic",
			active: [
				{
					roles: [Role.Actor, Role.Videoplayer],
					size: "cover",
					shape: "rect",
				},
			]
		},
	},

	ActDSrFit__Aud1Rl: {
		actors: {
			direction: "column",
			position: "right",
			type: "dynamic",
			active: [
				{
					roles: [Role.Actor, Role.Videoplayer],
					size: "dynamic",
					shape: "rect",
				},
			]
		},
		audience: {
			direction: "column",
			areas: [
				{
					position: "left",
					shape: "round",
					maxPanes: 4,
					maxItemsPerPane: 5,
					maxHeight: 142,
					maxWidth: 142,
					aspect: 1,
				},
			]
		}
	},

	ActDSrAsp__Aud1Rl: {
		actors: {
			direction: "column",
			position: "right",
			type: "dynamic",
			active: [
				{
					roles: [Role.Actor, Role.Videoplayer],
					size: "aspect",
					shape: "rect",
				},
			]
		},
		audience: {
			direction: "column",
			areas: [
				{
					position: "left",
					shape: "round",
					maxPanes: 4,
					maxItemsPerPane: 5,
					maxHeight: 120,
					maxWidth: 120,
					aspect: 1,
				},
			]
		}
	},

	Act1SrAsp__Aud1Rl: {
		actors: {
			direction: "column",
			position: "right",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Actor1,
					size: "aspect",
					shape: "rect",
				},
			]
		},
		audience: {
			direction: "column",
			areas: [
				{
					position: "left",
					shape: "round",
					maxPanes: 4,
					maxItemsPerPane: 5,
					maxHeight: 120,
					maxWidth: 120,
					aspect: 1,
				},
			]
		}
	},

	Act2SrAsp__Aud1Rl: {
		actors: {
			direction: "column",
			position: "right",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Actor2,
					size: "aspect",
					shape: "rect",
				},
			]
		},
		audience: {
			direction: "column",
			areas: [
				{
					position: "left",
					shape: "round",
					maxPanes: 4,
					maxItemsPerPane: 5,
					maxHeight: 120,
					maxWidth: 120,
					aspect: 1,
				},
			]
		}
	},

	Act3SrAsp__Aud1Rl: {
		actors: {
			direction: "column",
			position: "right",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Actor3,
					size: "aspect",
					shape: "rect",
				},
			]
		},
		audience: {
			direction: "column",
			areas: [
				{
					position: "left",
					shape: "round",
					maxPanes: 4,
					maxItemsPerPane: 5,
					maxHeight: 120,
					maxWidth: 120,
					aspect: 1,
				},
			]
		}
	},

	Vid1SrAsp__Aud1Rl: {
		actors: {
			direction: "column",
			position: "right",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Video1,
					size: "aspect",
					shape: "rect",
				},
			]
		},
		audience: {
			direction: "column",
			areas: [
				{
					position: "left",
					shape: "round",
					maxPanes: 4,
					maxItemsPerPane: 5,
					maxHeight: 120,
					maxWidth: 120,
					aspect: 1,
				},
			]
		}
	},


	Act1ScAsp__noAud: {
		actors: {
			direction: "row",
			position: "center",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Actor1,
					size: "aspect",
					shape: "rect",
				},
			]
		},
	},

	Act1ScFit__noAud: {
		actors: {
			direction: "row",
			position: "center",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Actor1 ,
					size: "cover",
					shape: "rect",
				},
			]
		},
	},

	Vid1ScAsp__noAud: {
		actors: {
			direction: "row",
			position: "center",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Video1 ,
					size: "aspect",
					shape: "rect",
				},
			]
		},
	},

	Vid1ScFit__noAud: {
		actors: {
			direction: "row",
			position: "center",
			type: "static",
			active: [
				{
					roleSpecifier: RoleSpecifier.Video1 ,
					size: "cover",
					shape: "rect",
				},
			]
		},
	},


}