import * as React from 'react';
import { SVGProps } from 'react';

const SvgExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.567 10.38c-.465 0-.8-.352-.8-.818v-.738l.176-3.067-2.311 2.434-2.707 2.725a.737.737 0 0 1-.554.229c-.5 0-.87-.326-.87-.818 0-.237.08-.448.237-.606l2.716-2.707 2.435-2.312-3.076.176h-.73c-.466 0-.826-.325-.826-.8 0-.474.351-.808.826-.808h4.878c.896 0 1.415.518 1.415 1.415v4.878a.798.798 0 0 1-.809.817Zm-9.641 9.66H5.048c-.897 0-1.424-.52-1.424-1.416v-4.878a.8.8 0 0 1 .817-.817c.457 0 .8.351.8.817v.738l-.184 3.068 2.32-2.435 2.698-2.724a.756.756 0 0 1 .563-.229c.492 0 .87.325.87.817a.843.843 0 0 1-.246.607l-2.707 2.707-2.444 2.311 3.077-.175h.738c.466 0 .826.325.826.8 0 .474-.36.808-.826.808Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgExpandIcon;
